export const localStorageBidKey = 'bid';

// Базовый url
const baseUrl = '/api/v1';

// Path для работы с шагами заявки
const bidsStepperPath = '/bids';

// Максимальный размер файла для загрузки
const oneMb = 1024 * 1024;
const maxSize = oneMb * 50; // 50MB
const maxSizeText = 50;

// Регулярное выражение для проверки email
export const emailRegex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

// Функция возвращающая корректный url в зависимости от шага и id заявки
export function getUrlByStep (step, bidId) {
  // Path для шагов завки с id
  const pathWithId = `${bidsStepperPath}/${bidId}`;

  // Объект с Path по шагам заявки
  const urlsByStep = {
    '1': `${bidsStepperPath}/step_phone`,
    '2': `${pathWithId}/step_project_status`,
    '3': `${pathWithId}/step_project_type`,
    '4': `${pathWithId}/step_additional_info`,
    '5': `${pathWithId}/step_user_email`
  };

  return baseUrl + urlsByStep[step];
}

// Получение текущей заявки
export function getBid () {
  const bidRow = localStorage.getItem(localStorageBidKey) ?? undefined;
  const bid = bidRow ? JSON.parse(bidRow) : undefined;
  return bid;
}

// Функция перехода к определенному шагу заявки
export function goToStep (step, removeFiles = false) {
  $("div[class^='bid_step']").fadeOut(300);
  $(`div[class^=bid_step${step}]`).delay(299).fadeIn(300); // Задержка нужна для более планого перехода
  updateStepInLocalStorage(step);

  $('.form_input_error').hide();

  if (removeFiles) $('.uploaded_file').remove();
}

// Обновление шага заявки для текущей заявки
export function updateStepInLocalStorage (step) {
  const bid = getBid();
  localStorage.setItem(localStorageBidKey, JSON.stringify({ ...bid, currentStep: step }));
}

// Показ елемента с ошибкой для поля с номера телефона
let phoneTimeoutId = 0;
function showPhoneError () {
  clearTimeout(phoneTimeoutId);
  $('.form_input_error_phone').fadeIn(1000).css('display', 'flex');
  phoneTimeoutId = setTimeout(() => {
    $('.form_input_error_phone').fadeOut(1000);
  }, 5000);
}

// Функция проверки инпута телефона первого шага заявки
export function inputPhoneCheck (value, valueСhanged) {
  if (value.length < 1) {
    showPhoneError();
    return false;
  }

  if (valueСhanged.length < 11) {
    $('.form_input_error_text_phone').html('Введите корректный номер');
    showPhoneError();
    return false;
  }

  return true;
}

// Функция проверки чекбокса первого шага заявки
let checkboxTimeoutId = 0;
export function inputCheckboxCheck (checkBoxInput) {
  clearTimeout(checkboxTimeoutId);
  const isChecked = checkBoxInput.is(':checked');
  if (!isChecked) {
    $('.form_input_error_check_box_step1').fadeIn(1000).css('display', 'flex');
    checkboxTimeoutId = setTimeout(() => {
      $('.form_input_error_check_box_step1').fadeOut(1000);
    }, 5000);
    return isChecked;
  }

  return isChecked;
}

// Объект с именами data-аттрибутов по шагу заявки
export const dataAttributNameByStep = {
  '2': 'project_status',
  '3': 'project_type'
};

// Функция установки текстовой информации для блока info на шаге 3, в зависимости от выбранного статуса проекта
export function setInfoTextInStep3 () {
  const bid = getBid();

  let textVariant = '';

  if (bid[dataAttributNameByStep[bid.currentStep]] === 'existing_project') {
    textVariant = 'уже существующий';
  } else {
    textVariant = 'новый';
  }

  $('.bid_step3 > .info').html(`Отлично, мы уже выяснили что это ${textVariant} проект, расскажите о нем подробнее`);
}

// Функция проверки инпута имени
let nameTimeoutId = 0;
export function inputNameCheck (value) {
  clearTimeout(nameTimeoutId);
  if (value.length < 1) {
    $('.form_input_error_name').fadeIn(1000).css('display', 'flex');
    nameTimeoutId = setTimeout(() => {
      $('.form_input_error_name').fadeOut(1000);
    }, 5000);
    return false;
  }
  return true;
}

// Проверка файла
export function checkFile (fileSize) {
  const fileInputError = $('.form_input_error_upload_file');

  if (fileSize > maxSize) {
    $('.form_input_error_text_upload_file').html(`Размер файла превышает ${maxSizeText}MB`);
    fileInputError.fadeIn(500).css('display', 'flex');

    return false;
  }

  fileInputError.fadeOut(500);

  return true;
}

// Функция очистки инпута с файлом
export function clearFileInput () {
  $('.file_name').html('Прикрепить файл');
  $('.file_size').html('до');
  $('#upload_file_input').val(null);
  $('.bid_remove_file_icon').fadeOut(100);
}

// Функция очистки формы шага 4
export function clearFormStep4 () {
  $('.form_input_name').val('');
  $('.form_input_company_name').val('');
  $('.form_input_textarea').val('');
  clearFileInput();
}

// Функция проверки email
export function inputEmailCheck (email, errorContainer ) {
  $(`#form_input_error_${errorContainer}`).fadeOut(100);
  setErrorTextForInputField();

  if (email.length < 1) {
    setErrorTextForInputField('Поле не может быть пустым');
    showErrorForInputField(errorContainer);

    return false;
  }

  const isValidEmail = emailRegex.test(email);

  if (!isValidEmail) {
    setErrorTextForInputField('Введите корректный email');
    showErrorForInputField(errorContainer);

    return false;
  }

  return true;
}

// Устанавливаем контент для сообщения ошибки текстового поля
function setErrorTextForInputField(text = '') {
  $('.form_input_error_text').html(text)
}

// Показ елемента с ошибкой для указанного елемента errorContainer
let emailTimeoutId = 0;
function showErrorForInputField (errorContainer) {
  clearTimeout(emailTimeoutId);
  $(`#form_input_error_${errorContainer}`).fadeIn(1000).css('display', 'flex').delay(5000).fadeOut(1000);
  emailTimeoutId = setTimeout(() => {
    $(`#form_input_error_${errorContainer}`).fadeOut(1000);
  }, 5000);
}

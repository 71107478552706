export const isMobile = navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/Android/i);

// Скролл до нужного блока с условием (если требуется)
export function scrollTo (selector, condition = true, time = 800) {
  const block = $(selector);
  const container = $('.main_content');
  const scrollPosition = block.offset().top - container.offset().top;

  if (condition) {
    $('html, body').animate({ scrollTop: scrollPosition }, time, function () {
      $('html, body').stop(true).animate();
    });
  }
}

// Функция проверки, находится ли элемент на экране более чем на половину
export function isInViewport (element) {
  const elementTop = $(element).offset().top;
  const elementBottom = elementTop + $(element).outerHeight() - 0.5 * $(window).height();

  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + 0.5 * $(window).height();

  return elementBottom > viewportTop && elementTop < viewportBottom;
}

// Функция поочередного появления и исчезания элементов на экране с анимацией
export function imagesToggleSlide (parent, images) {
  images.length > 0 && images.each((index, element) => {
    const delay = index * 200;

    $(element).delay(delay).fadeIn(500);
  });
}

// Бесконечная бегущая строка
export function ticker () {
  // Строка справа налево
  const tickerRight = document.querySelector('.ticker_right');
  const tickerRightList = document.querySelector('.ticker_list_right');
  const tickerRightClone = tickerRightList.cloneNode(true);

  tickerRight.append(tickerRightClone);

  if (document.querySelector('.ticker_left')) {
    // Строка слева направо
    const tickerLeft = document.querySelector('.ticker_left');
    const tickerLeftList = document.querySelector('.ticker_list_left');
    const tickerLeftClone = tickerLeftList.cloneNode(true);

    tickerLeft.append(tickerLeftClone);
  }
}

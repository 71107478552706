import { isMobile } from './main';

const canvas = document.getElementsByTagName('canvas')[0];

// Настройки для анимации брызг, следующих за курсором
const splatConfig = {
  SIM_RESOLUTION: 128,
  DYE_RESOLUTION: 1024,
  CAPTURE_RESOLUTION: 512,
  DENSITY_DISSIPATION: 4,
  VELOCITY_DISSIPATION: 4,
  PRESSURE: 0.39,
  PRESSURE_ITERATIONS: 20,
  CURL: 28,
  SPLAT_RADIUS: 300,
  SPLAT_FORCE: 6000,
  SHADING: true,
  COLORFUL: true,
  COLOR_UPDATE_SPEED: 10,
  PAUSED: false,
  BACK_COLOR: { r: 38, g: 32, b: 38 },
  TRANSPARENT: false,
  BLOOM: false,
  BLOOM_ITERATIONS: 8,
  BLOOM_RESOLUTION: 256,
  BLOOM_INTENSITY: 1,
  BLOOM_THRESHOLD: 1,
  BLOOM_SOFT_KNEE: 0.7,
  SUNRAYS: true,
  SUNRAYS_RESOLUTION: 196,
  SUNRAYS_WEIGHT: 1.0
};

// Класс используемый для хранения данных о точке ввода, такой как мышь или сенсорное касание
class ClassPointer {
  constructor () {
    this.id = -1; // Идентификатор
    this.texcoordX = 0; // Текущие координаты X
    this.texcoordY = 0; // Текущие координаты Y
    this.prevTexcoordX = 0; // Предыдущие координаты X
    this.prevTexcoordY = 0; // Предыдущие координаты Y
    this.deltaX = 0; // Изменение координат между текущим и предыдущим положением по X
    this.deltaY = 0; // Изменение координат между текущим и предыдущим положением по Y
    this.down = false; // Флаг, указывающий, нажата ли точка
    this.moved = false; // Флаг, указывающий, переместилась ли точка
    this.color = [30, 0, 300]; // Цвет
  }
}

// Массив для хранения объектов ClassPointer - точек касания/ввода
const pointers = [];

// Массив для хранения данных и брызгах
const splatStack = [];

// Получение контекста WebGL
// gl - Контекст WebGL, используемый для рендеринга графики на холсте
// ext - Дополнительные расширения WebGL
const { gl, ext } = getWebGLContext();

export function startMouseFollower () {
  // На мобильных устройствах разрешение текстуры уменьшается до 512 для улучшения производительности
  if (isMobile) {
    splatConfig.DYE_RESOLUTION = 512;
  }

  // Если устройство не поддерживает линейную фильтрацию текстур, разрешение текстуры уменьшается до 512,
  // а некоторые визуальные эффекты (shading, bloom, sunrays) отключаются для совместимости и улучшения производительности
  if (!ext.supportLinearFiltering) {
    splatConfig.DYE_RESOLUTION = 512;
    splatConfig.SHADING = false;
    splatConfig.BLOOM = false;
    splatConfig.SUNRAYS = false;
  }

  // Изменение размера холста в соответствии с текущими размерами окна
  resizeCanvas();

  // Создание первой точки
  pointers.push(new ClassPointer());

  updateKeywords();
  initFramebuffers();
  multipleSplats(parseInt(Math.random() * 20, 10) + 5);
  update();
}

// Настройка и инициализация контекста WebGL,
// а также настройка поддерживаемых форматов текстур и расширений
// для корректной работы с визуальными эффектами
function getWebGLContext () {
  const params = {
    alpha: true, // Прозрачность (альфа-канал)
    depth: false, // Буфер глубины (не нужен в данном случае)
    stencil: false, // Буфер трафарета (не нужен в данном случае)
    antialias: false, // Сглаживание (отключено)
    preserveDrawingBuffer: false // Сохранение буфера рисования (отключено для повышения производительности)
  };

  // Попытка создать контекст WebGL2
  let webgl = canvas.getContext('webgl2', params);

  // Проверка, удалось ли создать контекст WebGL2
  const isWebGL2Created = !!webgl;

  // Если WebGL2 не поддерживается, пытаемся создать WebGL контекст (WebGL1)
  if (!isWebGL2Created) webgl = canvas.getContext('webgl', params) || canvas.getContext('experimental-webgl', params);

  let halfFloat; // Переменная для хранения информации о типе полутоновых (half-float) текстур
  let supportLinearFiltering; // Переменная для хранения информации о поддержке линейной фильтрации текстур

  // Если поддерживается WebGL2, запрашиваем расширение для поддержки float буфера цвета
  // и проверяем поддержку линейной фильтрации для float текстур
  if (isWebGL2Created) {
    webgl.getExtension('EXT_color_buffer_float');
    supportLinearFiltering = webgl.getExtension('OES_texture_float_linear');
  } else {
    // Если используется WebGL1, запрашиваем расширение для полутоновых текстур
    // и проверяем поддержку линейной фильтрации для полутоновых текстур
    halfFloat = webgl.getExtension('OES_texture_half_float');
    supportLinearFiltering = webgl.getExtension('OES_texture_half_float_linear');
  }

  // Устанавливаем цвет очистки экрана (черный)
  webgl.clearColor(0.0, 0.0, 0.0, 1.0);

  // Определяем тип полутоновых текстур в зависимости от версии WebGL
  const halfFloatTexType = isWebGL2Created ? webgl.HALF_FLOAT : halfFloat.HALF_FLOAT_OES;

  // Переменные для хранения форматов текстур
  let formatRGBA;
  let formatRG;
  let formatR;

  // Если WebGL2, используем соответствующие форматы
  // Если WebGL1, используем форматы для WebGL1
  if (isWebGL2Created) {
    formatRGBA = getSupportedFormat(webgl, webgl.RGBA16F, webgl.RGBA, halfFloatTexType);
    formatRG = getSupportedFormat(webgl, webgl.RG16F, webgl.RG, halfFloatTexType);
    formatR = getSupportedFormat(webgl, webgl.R16F, webgl.RED, halfFloatTexType);
  } else {
    formatRGBA = getSupportedFormat(webgl, webgl.RGBA, webgl.RGBA, halfFloatTexType);
    formatRG = getSupportedFormat(webgl, webgl.RGBA, webgl.RGBA, halfFloatTexType);
    formatR = getSupportedFormat(webgl, webgl.RGBA, webgl.RGBA, halfFloatTexType);
  }

  // Возвращаем объект с контекстом WebGL и расширениями
  return {
    gl: webgl,
    ext: {
      formatRGBA,
      formatRG,
      formatR,
      halfFloatTexType,
      supportLinearFiltering
    }
  };
}

// Получение поддерживаемого формата текстуры для рендеринга
function getSupportedFormat (webgl, internalFormat, format, type) {
  // Проверяем, поддерживается ли формат для рендеринга в текстуру
  // Если формат не поддерживается, ищем поддерживаемый
  if (!isSupportRenderTextureFormat(webgl, internalFormat, format, type)) {
    switch (internalFormat) {
      case webgl.R16F:
        return getSupportedFormat(webgl, webgl.RG16F, webgl.RG, type);
      case webgl.RG16F:
        return getSupportedFormat(webgl, webgl.RGBA16F, webgl.RGBA, type);
      default:
        return null;
    }
  }

  return {
    internalFormat, // Внутренний формат текстуры
    format // Формат текстуры
  };
}

// Проверка, поддерживается ли указанный формат текстуры для рендеринга Framebuffer
function isSupportRenderTextureFormat (webgl, internalFormat, format, type) {
  // Создание текстуру
  const texture = webgl.createTexture();

  // Привязка текстуры к целевому типу TEXTURE_2D
  webgl.bindTexture(webgl.TEXTURE_2D, texture);

  // Установка параметров текстуры
  webgl.texParameteri(webgl.TEXTURE_2D, webgl.TEXTURE_MIN_FILTER, webgl.NEAREST);
  webgl.texParameteri(webgl.TEXTURE_2D, webgl.TEXTURE_MAG_FILTER, webgl.NEAREST);
  webgl.texParameteri(webgl.TEXTURE_2D, webgl.TEXTURE_WRAP_S, webgl.CLAMP_TO_EDGE);
  webgl.texParameteri(webgl.TEXTURE_2D, webgl.TEXTURE_WRAP_T, webgl.CLAMP_TO_EDGE);

  // Загрузка пустой текстуры с указанными внутренним форматом, форматом и типом
  webgl.texImage2D(webgl.TEXTURE_2D, 0, internalFormat, 4, 4, 0, format, type, null);

  // Создание Framebuffer
  const fbo = webgl.createFramebuffer();
  webgl.bindFramebuffer(webgl.FRAMEBUFFER, fbo);

  // Привязка текстуры к буферу кадра
  webgl.framebufferTexture2D(webgl.FRAMEBUFFER, webgl.COLOR_ATTACHMENT0, webgl.TEXTURE_2D, texture, 0);

  // Проверка статус буфера кадра
  const status = webgl.checkFramebufferStatus(webgl.FRAMEBUFFER);

  // Возвращает true, если буфер кадра корректен, иначе false
  return status === webgl.FRAMEBUFFER_COMPLETE;
}

// Класс предназначенный для управления шейдерными программами в WebGL
class Material {
  constructor (vertexShader, fragmentShaderSource) {
    // Переданные шейдеры
    this.vertexShader = vertexShader;
    this.fragmentShaderSource = fragmentShaderSource;

    // Массив для хранения созданных программ
    this.programs = [];

    // Активная программа шейдеров
    this.activeProgram = null;

    // Массив для хранения униформов активной программы
    this.uniforms = [];
  }

  // Установка ключевых слов для фрагментного шейдера
  setKeywords (keywords) {
    // Вычисление хэша на основе ключевых слов
    const hash = keywords.reduce((acc, keyword) => acc + hashCode(keyword), 0);

    // Получение программы по хэшу
    let program = this.programs[hash];

    // Если программы нет, компилируется новый фрагментный шейдер с учетом ключевых слов
    // и создается новая программа с использованием вершинного шейдера
    if (!program) {
      const fragmentShader = compileShader(gl.FRAGMENT_SHADER, this.fragmentShaderSource, keywords);

      program = createProgram(this.vertexShader, fragmentShader);

      this.programs[hash] = program;
    }

    // Если программа уже активна, действий не требуется
    if (program === this.activeProgram) return;

    // Получение униформы новой программы
    this.uniforms = getUniforms(program);

    // Новая программа становится активной
    this.activeProgram = program;
  }

  bind () {
    // Активация текущей программы
    gl.useProgram(this.activeProgram);
  }
}

// Класс предназначенный для создания и управления шейдерными программами в WebGL
class Program {
  constructor (vertexShader, fragmentShader) {
    // Создание объекта для хранения униформ
    this.uniforms = {};

    // Создание программы из вершинного и фрагментного шейдера
    this.program = createProgram(vertexShader, fragmentShader);

    // Получение униформы созданной программы
    this.uniforms = getUniforms(this.program);
  }

  bind () {
    // Активация программы для использования в WebGL
    gl.useProgram(this.program);
  }
}

// Создание и связывание программы шейдеров в WebGL
function createProgram (vertexShader, fragmentShader) {
  // Создаем новую программу шейдеров
  const program = gl.createProgram();

  // Прикрепляем вершинный и фрагментный шейдеры к программе
  gl.attachShader(program, vertexShader);
  gl.attachShader(program, fragmentShader);

  // Связываем шейдеры в программу
  gl.linkProgram(program);

  // Возвращаем программу шейдеров
  return program;
}

// Получение униформ из программы
function getUniforms (program) {
  const uniforms = [];

  const uniformCount = Array(gl.getProgramParameter(program, gl.ACTIVE_UNIFORMS));

  Array.from(uniformCount).forEach((_, index) => {
    const uniformName = gl.getActiveUniform(program, index).name;
    uniforms[uniformName] = gl.getUniformLocation(program, uniformName);
  });

  return uniforms;
}

// Компиляция шейдера
// принимает тип шейдера (ввершинный или фрагментный), его исходный код и набор ключевых слов,
// которые могут быть добавлены к исходному коду перед компиляцией
function compileShader (type, source, keywords) {
  // Добавляем ключевые слова (если они есть) к исходному коду шейдера
  const newSource = addKeywords(source, keywords);

  // Создаем новый объект шейдера указанного типа (ввершинный или фрагментный)
  const shader = gl.createShader(type);

  // Устанавливаем исходный код для этого шейдера
  gl.shaderSource(shader, newSource);

  gl.compileShader(shader);

  return shader;
}

// Добавление ключевые слова в виде препроцессорных директив (#define) к исходному коду шейдера
function addKeywords (source, keywords) {
  if (keywords == null) return source;

  let keywordsString = '';

  keywords.forEach((keyword) => { keywordsString += `#define ${keyword}\n`; });

  return keywordsString + source;
}

// Вершинный шейдер
// (для обработки вершин геометрии (точек, линий, треугольников и т.д.) перед их отрисовкой на экране)
const baseVertexShader = compileShader(gl.VERTEX_SHADER, `
  precision highp float;

  // атрибут для позиции вершины (подается из буфера атрибутов)
  attribute vec2 aPosition;

  // переменные, которые будут переданы в фрагментный шейдер
  varying vec2 vUv;  // Текстурные координаты текущей вершины
  varying vec2 vL;   // Текстурные координаты для левой соседней точки
  varying vec2 vR;   // Текстурные координаты для правой соседней точки
  varying vec2 vT;   // Текстурные координаты для верхней соседней точки
  varying vec2 vB;   // Текстурные координаты для нижней соседней точки

  // униформ для размера текстеля (размер одного пикселя в текстуре)
  uniform vec2 texelSize;

  void main () {
    // Преобразуем координаты вершины из диапазона [-1, 1] в [0, 1]
    vUv = aPosition * 0.5 + 0.5;

    // Вычисляем координаты для соседних точек с учетом размера текстеля
    vL = vUv - vec2(texelSize.x, 0.0); // Левая точка
    vR = vUv + vec2(texelSize.x, 0.0); // Правая точка
    vT = vUv + vec2(0.0, texelSize.y); // Верхняя точка
    vB = vUv - vec2(0.0, texelSize.y); // Нижняя точка

    // Устанавливаем позицию вершины в пространстве клиппинга
    gl_Position = vec4(aPosition, 0.0, 1.0);
  }
`);

// Вершинный шейдер для размытия
const blurVertexShader = compileShader(gl.VERTEX_SHADER, `
  precision highp float;

  // атрибут для позиции вершины (подается из буфера атрибутов)
  attribute vec2 aPosition;

  // переменные, которые будут переданы в фрагментный шейдер
  varying vec2 vUv;  // Текстурные координаты текущей вершины
  varying vec2 vL;   // Текстурные координаты для левой точки размытия
  varying vec2 vR;   // Текстурные координаты для правой точки размытия

  // Объявляем униформ для размера текстеля (размер одного пикселя в текстуре)
  uniform vec2 texelSize;

  void main () {
    // Преобразуем координаты вершины из диапазона [-1, 1] в [0, 1]
    vUv = aPosition * 0.5 + 0.5;

    // Задаем смещение для размытия (коэффициент смещения)
    float offset = 1.33333333;

    // Вычисляем текстурные координаты для левой и правой точек с учетом размера текстеля и смещения
    vL = vUv - texelSize * offset; // Левая точка размытия
    vR = vUv + texelSize * offset; // Правая точка размытия

    // Устанавливаем позицию вершины в пространстве клиппинга
    gl_Position = vec4(aPosition, 0.0, 1.0);
  }
`);

// Фрагментный шейдер для размытия,
const blurShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;

  // Указываем, что используем среднюю точность для семплеров 2D текстур
  precision mediump sampler2D;

  // Переменные, полученные из вершинного шейдера
  varying vec2 vUv; // Текстурные координаты текущего пикселя
  varying vec2 vL;  // Текстурные координаты для левой точки размытия
  varying vec2 vR;  // Текстурные координаты для правой точки размытия

  // Униформ для текстуры, с которой будем работать
  uniform sampler2D uTexture;

  void main () {
    // Создаем переменную для хранения итогового цвета
    vec4 sum = texture2D(uTexture, vUv) * 0.29411764;

    // Добавляем к итоговому цвету значение цвета из левой точки размытия
    sum += texture2D(uTexture, vL) * 0.35294117;

    // Добавляем к итоговому цвету значение цвета из правой точки размытия
    sum += texture2D(uTexture, vR) * 0.35294117;

    // Устанавливаем итоговый цвет фрагмента
    gl_FragColor = sum;
  }
`);

// Фрагментный шейдер для копирования текстуры
const copyShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;
  
    // Указываем среднюю точность для семплеров 2D текстур
  precision mediump sampler2D;

  // Переменная, полученная из вершинного шейдера, с высокой точностью
  varying highp vec2 vUv;

  // Униформа для текстуры, с которой будем работать
  uniform sampler2D uTexture;

  void main () {
    // Устанавливаем цвет текущего фрагмента, используя текстурные координаты
    gl_FragColor = texture2D(uTexture, vUv);
  }
`);

// Фрагментный шейдер для очистки или изменения текстуры с помощью множителя
const clearShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;
  
  // Указываем среднюю точность для семплеров 2D текстур
  precision mediump sampler2D;

  // Переменная, полученная из вершинного шейдера, с высокой точностью
  varying highp vec2 vUv;

  // Униформа для текстуры, с которой будем работать
  uniform sampler2D uTexture;

  // Униформа для множителя (значения), которым будем умножать цвет текстуры
  uniform float value;

  void main () {
    // Устанавливаем цвет текущего фрагмента, умножая значение цвета текстуры на значение множителя
    gl_FragColor = value * texture2D(uTexture, vUv);
  }
`);

// Фрагментный шейдер
const colorShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;

  // Униформа для цвета
  uniform vec4 color;

  void main () {
    // Устанавливаем цвет текущего фрагмента на значение из униформы color
    gl_FragColor = color;
  }
`);

// Фрагментный шейдер для создания шахматного узора на поверхности
const checkerboardShader = compileShader(gl.FRAGMENT_SHADER, `
  precision highp float;

  // Указываем высокую точность для семплеров 2D текстур
  precision highp sampler2D;

  // Переменная, полученная из вершинного шейдера, представляющая текстурные координаты текущего фрагмента
  varying vec2 vUv;

  // Униформа для текстуры, с которой будем работать (не используется в данном шейдере, но оставлена для универсальности)
  uniform sampler2D uTexture;

  // Униформа для соотношения сторон (ширина/высота) экрана или окна
  uniform float aspectRatio;

  // Определение масштаба для шахматного узора
  #define SCALE 25.0

  void main () {
    // Преобразование текстурных координат в координаты сетки
    vec2 uv = floor(vUv * SCALE * vec2(aspectRatio, 1.0));

    // Вычисление значения для текущей клетки шахматного узора
    float v = mod(uv.x + uv.y, 2.0);

    // Регулировка яркости клеток узора
    v = v * 0.1 + 0.8;

    // Установка итогового цвета фрагмента
    gl_FragColor = vec4(vec3(v), 1.0);
  }
`);

// Шейдер используется для отображения итогового изображения, включающего различные визуальные эффекты,
// такие как освещение (shading), эффекты цветения (bloom), солнечные лучи (sunrays) и дизеринг (dithering)
const displayShaderSource = `
  precision highp float;
  // Указываем высокую точность для семплеров 2D текстур
  precision highp sampler2D;

  // Переменные, переданные из вершинного шейдера
  varying vec2 vUv;
  varying vec2 vL;
  varying vec2 vR;
  varying vec2 vT;
  varying vec2 vB;

  // Текстурные юниформы
  uniform sampler2D uTexture;
  uniform sampler2D uBloom;
  uniform sampler2D uSunrays;
  uniform sampler2D uDithering;

  // Шкала для дизеринга
  uniform vec2 ditherScale;
  // Размер одного текстурного пикселя
  uniform vec2 texelSize;

  // Функция для преобразования цвета из линейного в гамма-корректированный
  vec3 linearToGamma (vec3 color) {
      color = max(color, vec3(0));
      return max(1.055 * pow(color, vec3(0.416666667)) - 0.055, vec3(0));
  }

  void main () {
    // Получаем цвет текущего пикселя из основной текстуры
    vec3 c = texture2D(uTexture, vUv).rgb;

    #ifdef SHADING
      // Если включено освещение, считаем его эффект
      vec3 lc = texture2D(uTexture, vL).rgb;
      vec3 rc = texture2D(uTexture, vR).rgb;
      vec3 tc = texture2D(uTexture, vT).rgb;
      vec3 bc = texture2D(uTexture, vB).rgb;

      // Рассчитываем градиенты в горизонтальном и вертикальном направлениях
      float dx = length(rc) - length(lc);
      float dy = length(tc) - length(bc);

      // Нормализуем вектор градиента и направление источника света
      vec3 n = normalize(vec3(dx, dy, length(texelSize)));
      vec3 l = vec3(0.0, 0.0, 1.0);

      // Рассчитываем диффузное освещение
      float diffuse = clamp(dot(n, l) + 0.7, 0.7, 1.0);
      c *= diffuse; // Применяем освещение к цвету
    #endif

    #ifdef BLOOM
        // Если включен bloom, получаем его значение из текстуры uBloom
        vec3 bloom = texture2D(uBloom, vUv).rgb;
    #endif

    #ifdef SUNRAYS
      // Если включены солнечные лучи, получаем их значение из текстуры uSunrays
      float sunrays = texture2D(uSunrays, vUv).r;

      c *= sunrays; // Применяем солнечные лучи к цвету

      #ifdef BLOOM
        bloom *= sunrays; // Применяем солнечные лучи к bloom
      #endif
    #endif

    #ifdef BLOOM
      // Если включен bloom, добавляем шум (дизеринг) к bloom
      float noise = texture2D(uDithering, vUv * ditherScale).r;
      noise = noise * 2.0 - 1.0;
      bloom += noise / 255.0; // Добавляем шум и масштабируем его
      bloom = linearToGamma(bloom); // Корректируем цвет bloom
      c += bloom; // Добавляем bloom к основному цвету
    #endif

    // Находим максимальное значение из цветовых каналов
    float a = max(c.r, max(c.g, c.b));

    // Устанавливаем итоговый цвет фрагмента
    gl_FragColor = vec4(c, a);
  }
`;

// Фрагментный шейдер предназначен для предобработки текстуры
// для эффекта яркого рассеянного подавляющего света (bloom)
const bloomPrefilterShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;
  precision mediump sampler2D;

  // Определяем входные переменные от вершинного шейдера
  varying vec2 vUv;

  // Определяем униформы (переменные, которые будут переданы в шейдер из программы)
  uniform sampler2D uTexture; // Текстура, к которой применяем шейдер
  uniform vec3 curve;         // Кривая тона, которая управляет блеском
  uniform float threshold;    // Порог яркости

  void main () {
    // Получаем цвет текселя (пикселя текстуры) из текстуры по координатам vUv
    vec3 c = texture2D(uTexture, vUv).rgb;

    // Находим максимальную компоненту цвета (яркость)
    float br = max(c.r, max(c.g, c.b));

    // Применяем кривую тона к яркости
    float rq = clamp(br - curve.x, 0.0, curve.y);
    rq = curve.z * rq * rq;

    // Применяем пороговое значение и корректируем цвет
    c *= max(rq, br - threshold) / max(br, 0.0001);

    // Устанавливаем итоговый цвет фрагмента (пикселя) в gl_FragColor
    gl_FragColor = vec4(c, 0.0); // Вектор из трех цветовых компонент и альфа-компоненты
  }
`);

// Фрагментный шейдер выполняет операцию размытия для эффекта блум (bloom)
const bloomBlurShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;
  precision mediump sampler2D;

  // Входные переменные, полученные от вершинного шейдера
  varying vec2 vL; // Координаты текстуры слева
  varying vec2 vR; // Координаты текстуры справа
  varying vec2 vT; // Координаты текстуры сверху
  varying vec2 vB; // Координаты текстуры снизу

  // Униформа (переменная, которая будет передана в шейдер из программы)
  uniform sampler2D uTexture; // Текстура, к которой применяется шейдер

  void main () {
    // Инициализируем сумму цветовых значений как вектор из четырех нулевых компонент
    vec4 sum = vec4(0.0);

    // Добавляем цвета из текстуры по координатам vL, vR, vT, vB
    sum += texture2D(uTexture, vL); // Цвет слева
    sum += texture2D(uTexture, vR); // Цвет справа
    sum += texture2D(uTexture, vT); // Цвет сверху
    sum += texture2D(uTexture, vB); // Цвет снизу

    // Среднее значение цветов
    sum *= 0.25;

    // Устанавливаем итоговый цвет фрагмента (пикселя) в gl_FragColor
    gl_FragColor = sum;
  }
`);

// Финальное размытие и интенсивность блум эффекта
const bloomFinalShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;
  precision mediump sampler2D;

  // Входные переменные, полученные от вершинного шейдера
  varying vec2 vL; // Координаты текстуры слева
  varying vec2 vR; // Координаты текстуры справа
  varying vec2 vT; // Координаты текстуры сверху
  varying vec2 vB; // Координаты текстуры снизу

  // Униформы (переменные, которые будут переданы в шейдер из программы)
  uniform sampler2D uTexture; // Текстура, к которой применяется шейдер
  uniform float intensity; // Интенсивность блум эффекта

  void main () {
    // Инициализируем сумму цветовых значений как вектор из четырех нулевых компонент
    vec4 sum = vec4(0.0);

    // Добавляем цвета из текстуры по координатам vL, vR, vT, vB
    sum += texture2D(uTexture, vL); // Цвет слева
    sum += texture2D(uTexture, vR); // Цвет справа
    sum += texture2D(uTexture, vT); // Цвет сверху
    sum += texture2D(uTexture, vB); // Цвет снизу

    // Среднее значение цветов
    sum *= 0.25;

    // Применяем интенсивность блум эффекта
    gl_FragColor = sum * intensity;
  }
`);

// Фрагментный шейдер для создания маски солнечных лучей
const sunraysMaskShader = compileShader(gl.FRAGMENT_SHADER, `
  precision highp float;
  precision highp sampler2D;

  // Входная переменная, полученная от вершинного шейдера
  varying vec2 vUv;

  // Униформа (переменная, которая будет передана в шейдер из программы)
  uniform sampler2D uTexture;

  void main () {
    // Получаем цветовое значение текущего пикселя из текстуры
    vec4 c = texture2D(uTexture, vUv);

    // Находим максимальную цветовую компоненту (яркость)
    float br = max(c.r, max(c.g, c.b));

    // Вычисляем значение альфа-канала на основе яркости
    // Умножаем яркость на 20 и ограничиваем значение от 0 до 0.8
    c.a = 1.0 - min(max(br * 20.0, 0.0), 0.8);

    // Устанавливаем цвет фрагмента
    gl_FragColor = c;
  }
`);

// Щейдер создает эффект солнечных лучей на основе маски солнечных лучей, созданной предыдущим шейдером
const sunraysShader = compileShader(gl.FRAGMENT_SHADER, `
  precision highp float;
  precision highp sampler2D;

  // Входная переменная, полученная от вершинного шейдера
  varying vec2 vUv;

  // Униформы (переменные, которые будут переданы в шейдер из программы)
  uniform sampler2D uTexture;
  uniform float weight;

  // Определяем количество итераций для эффекта солнечных лучей
  #define ITERATIONS 16

  void main () {
    // Параметры для эффекта солнечных лучей
    float Density = 0.3;
    float Decay = 0.95;
    float Exposure = 0.7;

    // Начальные координаты и направление
    vec2 coord = vUv;
    vec2 dir = vUv - 0.5;

    // Масштабируем направление
    dir *= 1.0 / float(ITERATIONS) * Density;
    float illuminationDecay = 1.0;

    // Начальное значение цвета (в данном случае альфа-канал)
    float color = texture2D(uTexture, vUv).a;

    // Главный цикл для создания эффекта солнечных лучей
    for (int i = 0; i < ITERATIONS; i++)
    {
      // Смещаем координаты в направлении от центра
      coord -= dir;
      // Получаем значение альфа-канала для новых координат
      float col = texture2D(uTexture, coord).a;
      // Добавляем освещенность с учетом затухания и веса
      color += col * illuminationDecay * weight;
      // Уменьшаем освещенность по мере удаления от центра
      illuminationDecay *= Decay;
    }

    // Устанавливаем цвет фрагмента с учетом экспозиции
    gl_FragColor = vec4(color * Exposure, 0.0, 0.0, 1.0);
  }
`);

// Шейдер создает эффект "брызг" (splat) на текстуре, добавляя цвет в определенную область
const splatShader = compileShader(gl.FRAGMENT_SHADER, `
  precision highp float;
  precision highp sampler2D;

  // Входная переменная, полученная от вершинного шейдера
  varying vec2 vUv;

  // Униформы (переменные, которые будут переданы в шейдер из программы)
  uniform sampler2D uTarget; // Текстура, на которую будем добавлять эффект
  uniform float aspectRatio; // Соотношение сторон текстуры
  uniform vec3 color; // Цвет брызг
  uniform vec2 point; // Координаты точки, где будет центр брызг
  uniform float radius; // Радиус эффекта брызг

  void main () {
    // Вычисляем вектор от текущей точки до центра брызг
    vec2 p = vUv - point.xy;

    // Корректируем координаты по соотношению сторон
    p.x *= aspectRatio;

    // Вычисляем значение брызг с экспоненциальным затуханием
    vec3 splat = exp(-dot(p, p) / radius) * color;

    // Получаем исходный цвет из текстуры
    vec3 base = texture2D(uTarget, vUv).xyz;

    // Устанавливаем итоговый цвет фрагмента, добавляя брызги к исходному цвету
    gl_FragColor = vec4(base + splat, 1.0);
  }
`);

// Шейдер для моделирования процесса переноса в контексте симуляций, таких как жидкости или дым
const advectionShader = compileShader(gl.FRAGMENT_SHADER, `
    precision highp float;
    precision highp sampler2D;

    varying vec2 vUv;                     // Интерполированные текстурные координаты из вершинного шейдера
    uniform sampler2D uVelocity;          // Текстура скорости
    uniform sampler2D uSource;            // Исходная текстура, которую нужно обновить
    uniform vec2 texelSize;               // Размер одного текселя для текстуры скорости
    uniform vec2 dyeTexelSize;            // Размер одного текселя для текстуры источника
    uniform float dt;                     // Шаг времени
    uniform float dissipation;            // Диссипация (распад)

    // Билинейная интерполяция текстуры
    vec4 bilerp (sampler2D sam, vec2 uv, vec2 tsize) {
      vec2 st = uv / tsize - 0.5;       // Преобразование текстурных координат

      vec2 iuv = floor(st);             // Интегральная часть координат
      vec2 fuv = fract(st);             // Дробная часть координат

      vec4 a = texture2D(sam, (iuv + vec2(0.5, 0.5)) * tsize);
      vec4 b = texture2D(sam, (iuv + vec2(1.5, 0.5)) * tsize);
      vec4 c = texture2D(sam, (iuv + vec2(0.5, 1.5)) * tsize);
      vec4 d = texture2D(sam, (iuv + vec2(1.5, 1.5)) * tsize);

      return mix(mix(a, b, fuv.x), mix(c, d, fuv.x), fuv.y);
    }

    void main () {
    // Условная компиляция для использования билинейной интерполяции вручную, если линейная фильтрация не поддерживается
    #ifdef MANUAL_FILTERING
      vec2 coord = vUv - dt * bilerp(uVelocity, vUv, texelSize).xy * texelSize;
      vec4 result = bilerp(uSource, coord, dyeTexelSize);
    #else
      vec2 coord = vUv - dt * texture2D(uVelocity, vUv).xy * texelSize;
      vec4 result = texture2D(uSource, coord);
    #endif
      float decay = 1.0 + dissipation * dt;
      gl_FragColor = result / decay;
  }`,
  ext.supportLinearFiltering ? null : ['MANUAL_FILTERING']
);

// Фрагментный шейдер для вычисления дивергенции векторного поля скорости
const divergenceShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;
  precision mediump sampler2D;

  varying highp vec2 vUv;                // Интерполированные текстурные координаты центра текущего текселя
  varying highp vec2 vL;                 // Интерполированные текстурные координаты левого текселя
  varying highp vec2 vR;                 // Интерполированные текстурные координаты правого текселя
  varying highp vec2 vT;                 // Интерполированные текстурные координаты верхнего текселя
  varying highp vec2 vB;                 // Интерполированные текстурные координаты нижнего текселя
  uniform sampler2D uVelocity;           // Текстура с векторным полем скорости

  void main () {
    float L = texture2D(uVelocity, vL).x;  // Скорость по горизонтали левого текселя
    float R = texture2D(uVelocity, vR).x;  // Скорость по горизонтали правого текселя
    float T = texture2D(uVelocity, vT).y;  // Скорость по вертикали верхнего текселя
    float B = texture2D(uVelocity, vB).y;  // Скорость по вертикали нижнего текселя

    vec2 C = texture2D(uVelocity, vUv).xy; // Скорость в центральном текселе

    // Проверяем, если тексель выходит за границы текстуры, то присваиваем ему отрицательную скорость
    if (vL.x < 0.0) { L = -C.x; }
    if (vR.x > 1.0) { R = -C.x; }
    if (vT.y > 1.0) { T = -C.y; }
    if (vB.y < 0.0) { B = -C.y; }

    // Вычисляем дивергенцию как половину разности сумм горизонтальных и вертикальных компонент скорости
    float div = 0.5 * (R - L + T - B);

    // Устанавливаем цвет фрагмента: d в красный канал, альфа-канал установлен в 1.0
    gl_FragColor = vec4(div, 0.0, 0.0, 1.0);
  }
`);

// Фрагментный шейдер для вычисления кривизны векторного поля скорости
const curlShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;
  precision mediump sampler2D;

  varying highp vec2 vUv;                // Интерполированные текстурные координаты центра текущего текселя
  varying highp vec2 vL;                 // Интерполированные текстурные координаты левого текселя
  varying highp vec2 vR;                 // Интерполированные текстурные координаты правого текселя
  varying highp vec2 vT;                 // Интерполированные текстурные координаты верхнего текселя
  varying highp vec2 vB;                 // Интерполированные текстурные координаты нижнего текселя
  uniform sampler2D uVelocity;           // Текстура с векторным полем скорости

  void main () {
    float L = texture2D(uVelocity, vL).y;  // Скорость по вертикали левого текселя
    float R = texture2D(uVelocity, vR).y;  // Скорость по вертикали правого текселя
    float T = texture2D(uVelocity, vT).x;  // Скорость по горизонтали верхнего текселя
    float B = texture2D(uVelocity, vB).x;  // Скорость по горизонтали нижнего текселя

    // Вычисляем вихрь как разницу между вертикальными и горизонтальными компонентами скорости
    float vorticity = R - L - T + B;

    // Устанавливаем цвет фрагмента: 0.5 * vorticity в красный канал, альфа-канал установлен в 1.0
    gl_FragColor = vec4(0.5 * vorticity, 0.0, 0.0, 1.0);
  }
`);

// Шейдер для моделирования вихревой составляющей векторного поля скорости в физической симуляции
const vorticityShader = compileShader(gl.FRAGMENT_SHADER, `
  precision highp float;
  precision highp sampler2D;

  varying vec2 vUv;                     // Интерполированные текстурные координаты центра текущего текселя
  varying vec2 vL;                      // Интерполированные текстурные координаты левого текселя
  varying vec2 vR;                      // Интерполированные текстурные координаты правого текселя
  varying vec2 vT;                      // Интерполированные текстурные координаты верхнего текселя
  varying vec2 vB;                      // Интерполированные текстурные координаты нижнего текселя
  uniform sampler2D uVelocity;          // Текстура с текущим векторным полем скорости
  uniform sampler2D uCurl;              // Текстура с предыдущими значениями кривизны
  uniform float curl;                   // Параметр для управления силой вихря
  uniform float dt;                     // Шаг времени

  void main () {
    float L = texture2D(uCurl, vL).x;  // Значение кривизны левого текселя
    float R = texture2D(uCurl, vR).x;  // Значение кривизны правого текселя
    float T = texture2D(uCurl, vT).x;  // Значение кривизны верхнего текселя
    float B = texture2D(uCurl, vB).x;  // Значение кривизны нижнего текселя
    float C = texture2D(uCurl, vUv).x; // Значение кривизны текущего текселя

    // Вычисляем силу вихря как вектор, направленный от точек с наибольшими изменениями кривизны
    vec2 force = 0.5 * vec2(abs(T) - abs(B), abs(R) - abs(L));
    force /= length(force) + 0.0001;  // Нормализуем вектор силы
    force *= curl * C;                // Умножаем на параметр curl и текущее значение кривизны
    force.y *= -1.0;                  // Инвертируем y-компоненту, чтобы сила воздействовала в нужном направлении

    vec2 velocity = texture2D(uVelocity, vUv).xy;  // Текущая скорость текселя
    velocity += force * dt;                       // Изменяем скорость в соответствии с вихревой силой и временным шагом
    velocity = min(max(velocity, -1000.0), 1000.0); // Ограничиваем скорость, чтобы предотвратить слишком большие значения

    gl_FragColor = vec4(velocity, 0.0, 1.0);   // Устанавливаем цвет фрагмента: новое значение скорости с альфа-каналом 1.0
  }
`);

// Шейдер для вычисления поля давления в физических симуляциях, таких как симуляция жидкости или газа
const pressureShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;
  precision mediump sampler2D;

  varying highp vec2 vUv;              // Интерполированные текстурные координаты текущего текселя
  varying highp vec2 vL;               // Интерполированные текстурные координаты левого текселя
  varying highp vec2 vR;               // Интерполированные текстурные координаты правого текселя
  varying highp vec2 vT;               // Интерполированные текстурные координаты верхнего текселя
  varying highp vec2 vB;               // Интерполированные текстурные координаты нижнего текселя
  uniform sampler2D uPressure;         // Текстура с текущими значениями давления
  uniform sampler2D uDivergence;       // Текстура с текущими значениями дивергенции

  void main () {
    float L = texture2D(uPressure, vL).x;   // Значение давления левого текселя
    float R = texture2D(uPressure, vR).x;   // Значение давления правого текселя
    float T = texture2D(uPressure, vT).x;   // Значение давления верхнего текселя
    float B = texture2D(uPressure, vB).x;   // Значение давления нижнего текселя
    float C = texture2D(uPressure, vUv).x;  // Значение давления текущего текселя
    float divergence = texture2D(uDivergence, vUv).x; // Значение дивергенции текущего текселя

    // Вычисляем новое значение давления как среднее между значениями соседей и коррекцией на дивергенцию
    float pressure = (L + R + T + B - divergence) * 0.25;

    gl_FragColor = vec4(pressure, 0.0, 0.0, 1.0); // Устанавливаем цвет фрагмента: новое значение давления с альфа-каналом 1.0
  }
`);

// Шейдер для вычитания градиента давления из вектора скорости
const gradientSubtractShader = compileShader(gl.FRAGMENT_SHADER, `
  precision mediump float;
  precision mediump sampler2D;

  varying highp vec2 vUv;              // Интерполированные текстурные координаты текущего текселя
  varying highp vec2 vL;               // Интерполированные текстурные координаты левого текселя
  varying highp vec2 vR;               // Интерполированные текстурные координаты правого текселя
  varying highp vec2 vT;               // Интерполированные текстурные координаты верхнего текселя
  varying highp vec2 vB;               // Интерполированные текстурные координаты нижнего текселя
  uniform sampler2D uPressure;         // Текстура с текущими значениями давления
  uniform sampler2D uVelocity;         // Текстура с текущими значениями вектора скорости

  void main () {
    float L = texture2D(uPressure, vL).x;   // Значение давления левого текселя
    float R = texture2D(uPressure, vR).x;   // Значение давления правого текселя
    float T = texture2D(uPressure, vT).x;   // Значение давления верхнего текселя
    float B = texture2D(uPressure, vB).x;   // Значение давления нижнего текселя
    vec2 velocity = texture2D(uVelocity, vUv).xy; // Значение вектора скорости текущего текселя

    // Вычитаем градиент давления из компонент вектора скорости
    velocity.xy -= vec2(R - L, T - B);

    gl_FragColor = vec4(velocity, 0.0, 1.0); // Устанавливаем цвет фрагмента: новое значение вектора скорости с альфа-каналом 1.0
  }
`);

// Настройка WebGL для рисования на экране или в целевом фреймбуфере (текстуре)
const blit = (() => {
  // Инициализация буферов и атрибутов
  gl.bindBuffer(gl.ARRAY_BUFFER, gl.createBuffer());
  gl.bufferData(gl.ARRAY_BUFFER, new Float32Array([-1, -1, -1, 1, 1, 1, 1, -1]), gl.STATIC_DRAW);

  // Инициализация индексного буфера
  gl.bindBuffer(gl.ELEMENT_ARRAY_BUFFER, gl.createBuffer());
  gl.bufferData(gl.ELEMENT_ARRAY_BUFFER, new Uint16Array([0, 1, 2, 0, 2, 3]), gl.STATIC_DRAW);

  // Настройка атрибутов вершин
  gl.vertexAttribPointer(0, 2, gl.FLOAT, false, 0, 0);
  gl.enableVertexAttribArray(0);

  return (target, clear = false) => {
    // Если целевой буфер (фреймбуфер) не указан, рисуем на экран
    if (target == null) {
      gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
      gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    } else {
      // Если указан целевой буфер (фреймбуфер), рисуем в него
      gl.viewport(0, 0, target.width, target.height);
      gl.bindFramebuffer(gl.FRAMEBUFFER, target.fbo);
    }

    // Если указан флаг clear, очищаем целевой буфер
    if (clear) {
      gl.clearColor(0.0, 0.0, 0.0, 1.0);
      gl.clear(gl.COLOR_BUFFER_BIT);
    }

    // Вызываем рендеринг, используя индексный буфер для прямоугольника
    gl.drawElements(gl.TRIANGLES, 6, gl.UNSIGNED_SHORT, 0);
  };
})();

// Объявление переменных для текстур и фреймбуферов
const bloomFramebuffers = []; // Массив фреймбуферов для bloom эффекта

let dye; // Текстура для симуляции распределения красителя
let velocity; // Текстура для симуляции скорости
let divergence; // Текстура для вычисления дивергенции
let curl; // Текстура для вычисления кривизны
let pressure; // Текстура для вычисления давления
let bloom; // Текстура для эффекта bloom
let sunrays; // Текстура для симуляции солнечных лучей
let sunraysTemp; // Временная текстура для солнечных лучей

// Загрузка текстуры для дрожания (дизеринга)
const ditheringTexture = createTextureAsync('LDR_LLL1_0.png');

// Инициализация программ шейдеров
const blurProgram = new Program(blurVertexShader, blurShader);
const copyProgram = new Program(baseVertexShader, copyShader);
const clearProgram = new Program(baseVertexShader, clearShader);
const colorProgram = new Program(baseVertexShader, colorShader);
const checkerboardProgram = new Program(baseVertexShader, checkerboardShader);
const bloomPrefilterProgram = new Program(baseVertexShader, bloomPrefilterShader);
const bloomBlurProgram = new Program(baseVertexShader, bloomBlurShader);
const bloomFinalProgram = new Program(baseVertexShader, bloomFinalShader);
const sunraysMaskProgram = new Program(baseVertexShader, sunraysMaskShader);
const sunraysProgram = new Program(baseVertexShader, sunraysShader);
const splatProgram = new Program(baseVertexShader, splatShader);
const advectionProgram = new Program(baseVertexShader, advectionShader);
const divergenceProgram = new Program(baseVertexShader, divergenceShader);
const curlProgram = new Program(baseVertexShader, curlShader);
const vorticityProgram = new Program(baseVertexShader, vorticityShader);
const pressureProgram = new Program(baseVertexShader, pressureShader);
const gradienSubtractProgram = new Program(baseVertexShader, gradientSubtractShader);

// Инициализация материала для отображения
const displayMaterial = new Material(baseVertexShader, displayShaderSource);

// Инициализация фреймбуферов
function initFramebuffers () {
  // Получаем разрешение для текстур с учетом конфигурации
  const simRes = getResolution(splatConfig.SIM_RESOLUTION); // Разрешение для симуляции
  const dyeRes = getResolution(splatConfig.DYE_RESOLUTION); // Разрешение для красителя

  // Определяем тип текстур и их форматы для хранения данных
  const texType = ext.halfFloatTexType; // Тип текстуры (например, половина float)
  const rgba = ext.formatRGBA; // Формат RGBA для цветовых данных
  const rg = ext.formatRG; // Формат RG для двухкомпонентных данных
  const r = ext.formatR; // Формат R для однокомпонентных данных
  const filtering = ext.supportLinearFiltering ? gl.LINEAR : gl.NEAREST; // Выбор типа фильтрации текстур

  // Отключаем режим смешивания цветов (BLEND)
  gl.disable(gl.BLEND);

  // Инициализация или изменение размера текстур и фреймбуферов
  // для различных составляющих симуляции

  // Текстура для красителя (dye)
  if (dye == null) dye = createDoubleFBO(dyeRes.width, dyeRes.height, rgba.internalFormat, rgba.format, texType, filtering);
  else dye = resizeDoubleFBO(dye, dyeRes.width, dyeRes.height, rgba.internalFormat, rgba.format, texType, filtering);

  // Текстура для скорости (velocity)
  if (velocity == null) velocity = createDoubleFBO(simRes.width, simRes.height, rg.internalFormat, rg.format, texType, filtering);
  else velocity = resizeDoubleFBO(velocity, simRes.width, simRes.height, rg.internalFormat, rg.format, texType, filtering);

  // Текстура для дивергенции (divergence)
  divergence = createFBO(simRes.width, simRes.height, r.internalFormat, r.format, texType, gl.NEAREST);

  // Текстура для кривизны (curl)
  curl = createFBO(simRes.width, simRes.height, r.internalFormat, r.format, texType, gl.NEAREST);

  // Двойная текстура для давления (pressure)
  pressure = createDoubleFBO(simRes.width, simRes.height, r.internalFormat, r.format, texType, gl.NEAREST);

  // Инициализация фреймбуферов для bloom эффекта
  initBloomFramebuffers();

  // Инициализация фреймбуферов для солнечных лучей
  initSunraysFramebuffers();
}

// Инициализация необходимых ресурсов (фреймбуферы и текстуры) для реализации bloom эффекта
function initBloomFramebuffers () {
  // Получаем разрешение для текстур блюра bloom эффекта
  const res = getResolution(splatConfig.BLOOM_RESOLUTION);

  // Определяем тип текстур и формат RGBA для хранения данных
  const texType = ext.halfFloatTexType; // Тип текстуры (например, половина float)
  const rgba = ext.formatRGBA; // Формат RGBA для цветовых данных
  const filtering = ext.supportLinearFiltering ? gl.LINEAR : gl.NEAREST; // Выбор типа фильтрации текстур

  // Создаем основной фреймбуфер для bloom эффекта
  bloom = createFBO(res.width, res.height, rgba.internalFormat, rgba.format, texType, filtering);

  // Очищаем массив фреймбуферов для bloom
  bloomFramebuffers.length = 0;

  // Создаем несколько фреймбуферов для множественных проходов блюра
  Array.from(splatConfig.BLOOM_ITERATIONS).forEach((_, index) => {
    // Вычисляем размеры текущего фреймбуфера с учетом уменьшения в размере
    const width = res.width >> (index + 1); // Побитовый сдвиг вправо на index + 1 (эффективное деление на 2^i)
    const height = res.height >> (index + 1); // Побитовый сдвиг вправо на index + 1 (эффективное деление на 2^i)

    // Если ширина или высота становятся меньше 2, останавливаем создание фреймбуферов
    if (width < 2 || height < 2) return;

    // Создаем новый фреймбуфер с вычисленными размерами
    const fbo = createFBO(width, height, rgba.internalFormat, rgba.format, texType, filtering);

    // Добавляем созданный фреймбуфер в массив для хранения
    bloomFramebuffers.push(fbo);
  });
}

// Инициализация фреймбуферов для солнечных лучей
function initSunraysFramebuffers () {
  // Получаем разрешение для текстур солнечных лучей из конфигурации splatConfig
  const res = getResolution(splatConfig.SUNRAYS_RESOLUTION);

  // Определяем тип текстур и формат данных для использования в WebGL
  const texType = ext.halfFloatTexType; // Тип текстуры (например, половина float)
  const r = ext.formatR; // Формат данных R для хранения одноканальной информации
  const filtering = ext.supportLinearFiltering ? gl.LINEAR : gl.NEAREST; // Выбор типа фильтрации текстур

  // Создаем текстуру и фреймбуфер для хранения солнечных лучей
  sunrays = createFBO(res.width, res.height, r.internalFormat, r.format, texType, filtering);
  sunraysTemp = createFBO(res.width, res.height, r.internalFormat, r.format, texType, filtering);
}

// Создание и настройка фреймбуфера с текстурой для хранения изображения
function createFBO (w, h, internalFormat, format, type, param) {
  // Активируем текстурный блок TEXTURE0
  gl.activeTexture(gl.TEXTURE0);
  // Создаем новую текстуру WebGL
  const texture = gl.createTexture();
  // Привязываем созданную текстуру к текстурному блоку 2D
  gl.bindTexture(gl.TEXTURE_2D, texture);

  // Устанавливаем параметры текстуры WebGL
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, param); // Минимальная фильтрация текстуры
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, param); // Максимальная фильтрация текстуры
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE); // Обертывание текстуры по оси S
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE); // Обертывание текстуры по оси T
  // Инициализируем текстуру пустыми данными
  gl.texImage2D(gl.TEXTURE_2D, 0, internalFormat, w, h, 0, format, type, null);

  // Создаем новый фреймбуфер WebGL
  const fbo = gl.createFramebuffer();
  // Привязываем созданный фреймбуфер WebGL
  gl.bindFramebuffer(gl.FRAMEBUFFER, fbo);
  // Привязываем текстуру к цветовому вложению фреймбуфера
  gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, texture, 0);
  // Устанавливаем область просмотра (viewport) для фреймбуфера
  gl.viewport(0, 0, w, h);
  // Очищаем цветовой буфер фреймбуфера
  gl.clear(gl.COLOR_BUFFER_BIT);

  // Рассчитываем размер texel для удобства использования при обработке текстурных данных
  const texelSizeX = 1.0 / w;
  const texelSizeY = 1.0 / h;

  // Возвращаем объект с информацией о созданном фреймбуфере
  return {
    texture, // Объект текстуры WebGL
    fbo, // Объект фреймбуфера WebGL
    width: w, // Ширина текстуры
    height: h, // Высота текстуры
    texelSizeX, // Размер texel по оси X
    texelSizeY, // Размер texel по оси Y
    attach (id) { // Метод для привязки текстуры к текстурному блоку
      gl.activeTexture(gl.TEXTURE0 + id);
      gl.bindTexture(gl.TEXTURE_2D, texture);
      return id;
    }
  };
}

// Создание двойного фреймбуфера с двумя текстурами для хранения изображений
function createDoubleFBO (w, h, internalFormat, format, type, param) {
  // Создаем два отдельных фреймбуфера с использованием функции createFBO
  let fbo1 = createFBO(w, h, internalFormat, format, type, param);
  let fbo2 = createFBO(w, h, internalFormat, format, type, param);

  // Возвращаем объект, представляющий двойной фреймбуфер
  return {
    width: w, // Ширина текстур в пикселях
    height: h, // Высота текстур в пикселях
    texelSizeX: fbo1.texelSizeX, // Размер texel по оси X
    texelSizeY: fbo1.texelSizeY, // Размер texel по оси Y
    // Геттер для получения текущего фреймбуфера для чтения
    get read () {
      return fbo1;
    },
    // Сеттер для установки нового фреймбуфера для чтения
    set read (value) {
      fbo1 = value;
    },
    // Геттер для получения текущего фреймбуфера для записи
    get write () {
      return fbo2;
    },
    // Сеттер для установки нового фреймбуфера для записи
    set write (value) {
      fbo2 = value;
    },
    // Метод для обмена местами фреймбуферов (чтение и запись)
    swap () {
      const temp = fbo1;
      fbo1 = fbo2;
      fbo2 = temp;
    }
  };
}

// Изменение размера существующего фреймбуфера с сохранением данных
function resizeFBO (target, w, h, internalFormat, format, type, param) {
  // Создаем новый фреймбуфер с новыми размерами
  const newFBO = createFBO(w, h, internalFormat, format, type, param);

  // Используем программу копирования для копирования данных из старого фреймбуфера в новый
  copyProgram.bind();
  gl.uniform1i(copyProgram.uniforms.uTexture, target.attach(0));
  blit(newFBO); // Функция blit используется для выполнения копирования данных между фреймбуферами

  // Возвращаем новый фреймбуфер
  return newFBO;
}

// Изменение размера двойного фреймбуфера с сохранением данных
function resizeDoubleFBO (target, w, h, internalFormat, format, type, param) {
  // Проверяем, совпадают ли текущие размеры с новыми
  if (target.width === w && target.height === h) return target; // Если размеры совпадают, возвращаем исходный фреймбуфер без изменений

  // Изменяем фреймбуфер чтения на новый фреймбуфер с новыми размерами
  target.read = resizeFBO(target.read, w, h, internalFormat, format, type, param);
  // Создаем новый фреймбуфер записи с новыми размерами
  target.write = createFBO(w, h, internalFormat, format, type, param);

  // Обновляем ширину и высоту двойного фреймбуфера
  target.width = w;
  target.height = h;

  // Вычисляем размер texel для обновленных размеров фреймбуфера
  target.texelSizeX = 1.0 / w;
  target.texelSizeY = 1.0 / h;

  // Возвращаем обновленный двойной фреймбуфер
  return target;
}

// Создание текстуры WebGL асинхронно на основе изображения, загруженного по URL
function createTextureAsync (url) {
  // Создаем объект WebGL текстуры
  const texture = gl.createTexture();
  // Привязываем текстуру к текущему активному текстурному блоку 2D
  gl.bindTexture(gl.TEXTURE_2D, texture);

  // Устанавливаем параметры фильтрации и обертывания текстуры WebGL
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR); // Минимальная фильтрация
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR); // Максимальная фильтрация
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.REPEAT); // Обертывание текстуры по оси S
  gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.REPEAT); // Обертывание текстуры по оси T

  // Инициализируем текстуру пустыми данными определенного размера (1x1 пиксель)
  gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, 1, 1, 0, gl.RGB, gl.UNSIGNED_BYTE, new Uint8Array([255, 255, 255]));

  // Создаем объект для хранения информации о текстуре
  const obj = {
    texture, // WebGL текстура
    width: 1, // Ширина текстуры (начально 1 пиксель)
    height: 1, // Высота текстуры (начально 1 пиксель)
    attach (id) { // Метод для привязки текстуры к текстурному блоку с определенным ID
      gl.activeTexture(gl.TEXTURE0 + id);
      gl.bindTexture(gl.TEXTURE_2D, texture);
      return id;
    }
  };

  // Создаем новый объект изображения для загрузки из URL
  const image = new Image();
  // Устанавливаем обработчик события загрузки изображения
  image.onload = () => {
    obj.width = image.width; // Устанавливаем фактическую ширину изображения в объекте текстуры
    obj.height = image.height; // Устанавливаем фактическую высоту изображения в объекте текстуры
    gl.bindTexture(gl.TEXTURE_2D, texture); // Привязываем текстуру к текущему активному текстурному блоку
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGB, gl.RGB, gl.UNSIGNED_BYTE, image); // Устанавливаем изображение в текстуру WebGL
  };

  // Загружаем изображение по указанному URL
  image.src = url;

  // Возвращаем объект с информацией о созданной текстуре WebGL
  return obj;
}

// Обновление ключевыx слов для материала отображения на основе текущей конфигурации splatConfig
function updateKeywords () {
  const displayKeywords = [];

  if (splatConfig.SHADING) displayKeywords.push('SHADING');
  if (splatConfig.BLOOM) displayKeywords.push('BLOOM');
  if (splatConfig.SUNRAYS) displayKeywords.push('SUNRAYS');

  displayMaterial.setKeywords(displayKeywords);
}

// Время последнего обновления, начинаем с текущего момента
let lastUpdateTime = Date.now();

// Таймер обновления цветов, начальное значение 0
let colorUpdateTimer = 0.0;

// Обновление сцены. Вызывается каждый кадр
function update () {
  // Рассчитываем прошедшее время с последнего кадра
  const dt = calcDeltaTime();

  // Проверяем, изменились ли размеры холста
  // Переинициализируем фреймбуферы WebGL при изменении размеров
  if (resizeCanvas()) initFramebuffers();

  // Обновляем цвета в зависимости от прошедшего времени
  updateColors(dt);

  // Применяем ввод пользователя (например, с помощью указателей мыши или сенсоров)
  applyInputs();

  // Если симуляция не приостановлена
  // Выполняем шаг симуляции
  if (!splatConfig.PAUSED) step(dt);

  // Отрисовываем сцену
  render(null);

  // Планируем следующий кадр для обновления
  requestAnimationFrame(update);
}

// Рассчитывает прошедшее время с последнего кадра в секундах
function calcDeltaTime () {
  const now = Date.now();

  let dt = (now - lastUpdateTime) / 1000;

  dt = Math.min(dt, 0.016666);

  lastUpdateTime = now;

  return dt;
}

// Изменение размеров холста WebGL в соответствии с размерами клиентской области
function resizeCanvas () {
  const width = scaleByPixelRatio(canvas.clientWidth);
  const height = scaleByPixelRatio(canvas.clientHeight);

  if (canvas.width !== width || canvas.height !== height) {
    canvas.width = width;
    canvas.height = height;
    return true;
  }

  return false;
}

// Обновление цвета всех указателей в зависимости от прошедшего времени.
function updateColors (dt) {
  // Если опция COLORFUL не активна, выходим
  if (!splatConfig.COLORFUL) return;

  // Увеличиваем таймер обновления цветов
  colorUpdateTimer += dt * splatConfig.COLOR_UPDATE_SPEED;

  // Если таймер достигает или превышает 1
  // Оборачиваем таймер в диапазон от 0 до 1
  if (colorUpdateTimer >= 1) {
    colorUpdateTimer = wrap(colorUpdateTimer, 0, 1);

    // Генерируем новый цвет для каждого указателя
    pointers.forEach((p) => { p.color = generateColor(); });
  }
}

// Применение пользовательского ввода к симуляции
function applyInputs () {
  // Применение мультиточечных капель из стека
  if (splatStack.length > 0) multipleSplats(splatStack.pop());

  // Обработка перемещения каждого указателя
  pointers.forEach((p) => {
    if (p.moved) {
      p.moved = false;
      splatPointer(p); // Создание капли в месте перемещения указателя
    }
  });
}

// Один шаг симуляции, включающий расчёт вихревости, давления, адвекции и других физических процессов
function step (dt) {
  gl.disable(gl.BLEND); // Отключение смешивания цветов для всех последующих рендеров

  // Расчёт вихревости
  curlProgram.bind();
  gl.uniform2f(curlProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
  gl.uniform1i(curlProgram.uniforms.uVelocity, velocity.read.attach(0));
  blit(curl); // Применение программы расчёта вихревости

  // Расчёт вихревости в программе вихревости
  vorticityProgram.bind();
  gl.uniform2f(vorticityProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
  gl.uniform1i(vorticityProgram.uniforms.uVelocity, velocity.read.attach(0));
  gl.uniform1i(vorticityProgram.uniforms.uCurl, curl.attach(1));
  gl.uniform1f(vorticityProgram.uniforms.curl, splatConfig.CURL);
  gl.uniform1f(vorticityProgram.uniforms.dt, dt);
  blit(velocity.write);
  velocity.swap(); // Переключение текстур для следующего шага

  // Расчёт дивергенции
  divergenceProgram.bind();
  gl.uniform2f(divergenceProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
  gl.uniform1i(divergenceProgram.uniforms.uVelocity, velocity.read.attach(0));
  blit(divergence); // Применение программы расчёта дивергенции

  // Очистка текстуры давления
  clearProgram.bind();
  gl.uniform1i(clearProgram.uniforms.uTexture, pressure.read.attach(0));
  gl.uniform1f(clearProgram.uniforms.value, splatConfig.PRESSURE);
  blit(pressure.write);
  pressure.swap(); // Переключение текстур для следующего шага

  // Расчёт давления
  pressureProgram.bind();
  gl.uniform2f(pressureProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
  gl.uniform1i(pressureProgram.uniforms.uDivergence, divergence.attach(0));
  Array.from(splatConfig.PRESSURE_ITERATIONS).forEach(() => {
    gl.uniform1i(pressureProgram.uniforms.uPressure, pressure.read.attach(1));
    blit(pressure.write);
    pressure.swap(); // Переключение текстур для следующего шага давления
  });

  // Вычитание градиента
  gradienSubtractProgram.bind();
  gl.uniform2f(gradienSubtractProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
  gl.uniform1i(gradienSubtractProgram.uniforms.uPressure, pressure.read.attach(0));
  gl.uniform1i(gradienSubtractProgram.uniforms.uVelocity, velocity.read.attach(1));
  blit(velocity.write);
  velocity.swap(); // Переключение текстур для следующего шага

  // Адвекция
  advectionProgram.bind();
  gl.uniform2f(advectionProgram.uniforms.texelSize, velocity.texelSizeX, velocity.texelSizeY);
  if (!ext.supportLinearFiltering) gl.uniform2f(advectionProgram.uniforms.dyeTexelSize, velocity.texelSizeX, velocity.texelSizeY);
  const velocityId = velocity.read.attach(0);
  gl.uniform1i(advectionProgram.uniforms.uVelocity, velocityId);
  gl.uniform1i(advectionProgram.uniforms.uSource, velocityId);
  gl.uniform1f(advectionProgram.uniforms.dt, dt);
  gl.uniform1f(advectionProgram.uniforms.dissipation, splatConfig.VELOCITY_DISSIPATION);
  blit(velocity.write);
  velocity.swap(); // Переключение текстур для следующего шага

  // Проверка поддержки линейной фильтрации текстур
  if (!ext.supportLinearFiltering) gl.uniform2f(advectionProgram.uniforms.dyeTexelSize, dye.texelSizeX, dye.texelSizeY);

  gl.uniform1i(advectionProgram.uniforms.uVelocity, velocity.read.attach(0));
  gl.uniform1i(advectionProgram.uniforms.uSource, dye.read.attach(1));
  gl.uniform1f(advectionProgram.uniforms.dissipation, splatConfig.DENSITY_DISSIPATION);

  blit(dye.write);

  dye.swap(); // Переключение текстур для следующего шага
}

// Отображение графики на холсте
function render (target) {
  // Применяем эффект bloom, если включен
  if (splatConfig.BLOOM) applyBloom(dye.read, bloom);

  // Применяем эффект sunrays, если включен
  if (splatConfig.SUNRAYS) {
    applySunrays(dye.read, dye.write, sunrays);
    blur(sunrays, sunraysTemp, 1);
  }

  // Устанавливаем режим наложения для прозрачности или основного холста
  if (target == null || !splatConfig.TRANSPARENT) {
    gl.blendFunc(gl.ONE, gl.ONE_MINUS_SRC_ALPHA); // Устанавливаем функцию смешивания для прозрачности
    gl.enable(gl.BLEND); // Включаем режим смешивания
  } else {
    gl.disable(gl.BLEND); // Отключаем режим смешивания
  }

  // Рисуем цветной фон, если он включен и не используем прозрачность
  if (!splatConfig.TRANSPARENT) drawColor(target, normalizeColor(splatConfig.BACK_COLOR));

  // Рисуем шахматную доску, если холст пустой и используется прозрачность
  if (target == null && splatConfig.TRANSPARENT) drawCheckerboard(target);

  // Рисуем основной кадр сцены
  drawDisplay(target);
}

// Отрисовка цветного прямоугольника на холсте
function drawColor (target, color) {
  colorProgram.bind(); // Привязываем шейдерную программу для отрисовки цвета
  gl.uniform4f(colorProgram.uniforms.color, color.r, color.g, color.b, 1); // Устанавливаем цвет
  blit(target); // Производим отрисовку на целевой холст
}

// Отрисовка шахматной доски на холсте
function drawCheckerboard (target) {
  checkerboardProgram.bind(); // Привязываем шейдерную программу для отрисовки шахматной доски
  gl.uniform1f(checkerboardProgram.uniforms.aspectRatio, canvas.width / canvas.height); // Устанавливаем соотношение сторон
  blit(target); // Производим отрисовку на целевой холст
}

// Отрисовка основного кадра сцены на холсте
function drawDisplay (target) {
  const width = target == null ? gl.drawingBufferWidth : target.width; // Получаем ширину холста
  const height = target == null ? gl.drawingBufferHeight : target.height; // Получаем высоту холста

  displayMaterial.bind(); // Привязываем материал для отрисовки основного кадра
  if (splatConfig.SHADING) gl.uniform2f(displayMaterial.uniforms.texelSize, 1.0 / width, 1.0 / height); // Устанавливаем размеры текстуры в шейдере

  gl.uniform1i(displayMaterial.uniforms.uTexture, dye.read.attach(0)); // Привязываем текстуру для отображения

  // Если включен эффект bloom, передаем дополнительные текстуры и параметры
  if (splatConfig.BLOOM) {
    gl.uniform1i(displayMaterial.uniforms.uBloom, bloom.attach(1));
    gl.uniform1i(displayMaterial.uniforms.uDithering, ditheringTexture.attach(2));
    const scale = getTextureScale(ditheringTexture, width, height);
    gl.uniform2f(displayMaterial.uniforms.ditherScale, scale.x, scale.y);
  }

  // Если включен эффект sunrays, передаем текстуру для отображения солнечных лучей
  if (splatConfig.SUNRAYS) gl.uniform1i(displayMaterial.uniforms.uSunrays, sunrays.attach(3));

  blit(target); // Производим отрисовку на целевой холст
}

// Применение эффекта блум
function applyBloom (source, destination) {
  // Проверяем, есть ли достаточное количество буферов для блюра блюма
  if (bloomFramebuffers.length < 2) return;

  let last = destination;

  // Отключаем режим смешивания и применяем программу предварительной фильтрации блюра
  gl.disable(gl.BLEND);
  bloomPrefilterProgram.bind();
  const knee = splatConfig.BLOOM_THRESHOLD * splatConfig.BLOOM_SOFT_KNEE + 0.0001;
  const curve0 = splatConfig.BLOOM_THRESHOLD - knee;
  const curve1 = knee * 2;
  const curve2 = 0.25 / knee;
  gl.uniform3f(bloomPrefilterProgram.uniforms.curve, curve0, curve1, curve2);
  gl.uniform1f(bloomPrefilterProgram.uniforms.threshold, splatConfig.BLOOM_THRESHOLD);
  gl.uniform1i(bloomPrefilterProgram.uniforms.uTexture, source.attach(0));
  blit(last);

  // Применяем программу размытия блюра
  bloomBlurProgram.bind();
  Array.from(bloomFramebuffers.length).forEach((_, index) => {
    const dest = bloomFramebuffers[index];
    gl.uniform2f(bloomBlurProgram.uniforms.texelSize, last.texelSizeX, last.texelSizeY);
    gl.uniform1i(bloomBlurProgram.uniforms.uTexture, last.attach(0));
    blit(dest);
    last = dest;
  });

  // Настраиваем режим смешивания для накладывания результатов блюра
  gl.blendFunc(gl.ONE, gl.ONE);
  gl.enable(gl.BLEND);

  // Применяем обратное накладывание блюра для усиления эффекта
  for (let i = bloomFramebuffers.length - 2; i >= 0; i--) {
    const baseTex = bloomFramebuffers[i];
    gl.uniform2f(bloomBlurProgram.uniforms.texelSize, last.texelSizeX, last.texelSizeY);
    gl.uniform1i(bloomBlurProgram.uniforms.uTexture, last.attach(0));
    gl.viewport(0, 0, baseTex.width, baseTex.height);
    blit(baseTex);
    last = baseTex;
  }

  // Отключаем режим смешивания и применяем окончательный шейдер блюра
  gl.disable(gl.BLEND);
  bloomFinalProgram.bind();
  gl.uniform2f(bloomFinalProgram.uniforms.texelSize, last.texelSizeX, last.texelSizeY);
  gl.uniform1i(bloomFinalProgram.uniforms.uTexture, last.attach(0));
  gl.uniform1f(bloomFinalProgram.uniforms.intensity, splatConfig.BLOOM_INTENSITY);
  blit(destination);
}

// Применение эффекта солнечных лучей
function applySunrays (source, mask, destination) {
  // Отключаем режим смешивания и применяем программу маскировки солнечных лучей
  gl.disable(gl.BLEND);
  sunraysMaskProgram.bind();
  gl.uniform1i(sunraysMaskProgram.uniforms.uTexture, source.attach(0));
  blit(mask);

  // Применяем программу солнечных лучей
  sunraysProgram.bind();
  gl.uniform1f(sunraysProgram.uniforms.weight, splatConfig.SUNRAYS_WEIGHT);
  gl.uniform1i(sunraysProgram.uniforms.uTexture, mask.attach(0));
  blit(destination);
}

// Блюр
function blur (target, temp, iterations) {
  // Привязываем программу размытия
  blurProgram.bind();

  // Проводим несколько итераций размытия (горизонтальное и вертикальное)
  Array.from(iterations).forEach(() => {
    // Горизонтальное размытие
    gl.uniform2f(blurProgram.uniforms.texelSize, target.texelSizeX, 0.0);
    gl.uniform1i(blurProgram.uniforms.uTexture, target.attach(0));
    blit(temp); // Отрисовываем во временный буфер

    // Вертикальное размытие
    gl.uniform2f(blurProgram.uniforms.texelSize, 0.0, target.texelSizeY);
    gl.uniform1i(blurProgram.uniforms.uTexture, temp.attach(0));
    blit(target); // Отрисовываем в целевой буфер
  });
}

// Отрисовка брызг на точке
function splatPointer (pointer) {
  // Вычисляем силу брызг на основе изменения координат указателя и конфигурационной силы брызг
  const dx = pointer.deltaX * splatConfig.SPLAT_FORCE;
  const dy = pointer.deltaY * splatConfig.SPLAT_FORCE;

  // Вызываем функцию splat для создания эффекта брызг на заданных координатах
  splat(pointer.texcoordX, pointer.texcoordY, dx, dy, pointer.color);
}

// Генерация нескольких случайных эффектов брызг с различными цветами и скоростями
function multipleSplats (amount) {
  Array.from(amount).forEach(() => {
    // Генерируем случайный цвет
    const color = generateColor();
    // Увеличиваем интенсивность цвета для более ярких эффектов
    color.r *= 10.0;
    color.g *= 10.0;
    color.b *= 10.0;
    // Генерируем случайные координаты и скорости для брызг
    const x = Math.random();
    const y = Math.random();
    const dx = 1000 * (Math.random() - 0.5);
    const dy = 1000 * (Math.random() - 0.5);
    // Вызываем функцию splat для создания эффекта брызг с заданными параметрами
    splat(x, y, dx, dy, color);
  });
}

// Применение эффекта брызг
function splat (x, y, dx, dy, color) {
  // Привязываем программу брызг (splatProgram)
  splatProgram.bind();
  // Устанавливаем текстуру для записи скорости (velocity.read) в юниформ-переменную
  gl.uniform1i(splatProgram.uniforms.uTarget, velocity.read.attach(0));
  // Устанавливаем соотношение сторон для правильного расчета точек в юниформ-переменную
  gl.uniform1f(splatProgram.uniforms.aspectRatio, canvas.width / canvas.height);
  // Устанавливаем точку брызг в юниформ-переменную
  gl.uniform2f(splatProgram.uniforms.point, x, y);
  // Устанавливаем цветовую информацию (скорость брызг) в юниформ-переменную
  gl.uniform3f(splatProgram.uniforms.color, dx, dy, 0.0);
  // Устанавливаем радиус брызг, корректируя его для соответствия размерам экрана
  gl.uniform1f(splatProgram.uniforms.radius, correctRadius(splatConfig.SPLAT_RADIUS / 100.0));
  // Применяем смазывание к текстуре записи скорости (velocity.write)
  blit(velocity.write);
  // Меняем местами текстуры для чтения и записи
  velocity.swap();

  // Устанавливаем текстуру для записи цвета (dye.read) в юниформ-переменную
  gl.uniform1i(splatProgram.uniforms.uTarget, dye.read.attach(0));
  // Устанавливаем цветовую информацию в юниформ-переменную
  gl.uniform3f(splatProgram.uniforms.color, color.r, color.g, color.b);
  // Применяем смазывание цвета к текстуре записи цвета (dye.write)
  blit(dye.write);
  // Меняем местами текстуры для чтения и записи
  dye.swap();
}

// Корректировка радиуса брызг для соответствия размерам экрана
function correctRadius (radius) {
  // Вычисляем соотношение сторон экрана
  const aspectRatio = canvas.width / canvas.height;

  // Вычисляем минимальный размер экрана для нормализации радиуса
  const minSize = Math.max(canvas.width, canvas.height);

  // Нормализуем радиус относительно минимального размера экрана и соотношения сторон
  const normalizedRadius = radius / minSize;

  return normalizedRadius * aspectRatio;
}

// Отрисовка при слежении за мышкой
export function onMouseMoveFollow ({ pageX, pageY }) {
  // Получаем первый указатель из массива pointers
  const pointer = pointers[0];

  // Масштабируем позицию указателя по пиксельному отношению
  const posX = scaleByPixelRatio(pageX);
  const posY = scaleByPixelRatio(pageY);

  // Обновляем данные о движении указателя
  updatePointerMoveData(pointer, posX, posY);
}

// Добавление отслеживания события начала касания
canvas.addEventListener('touchstart', (e) => {
  e.preventDefault();

  const touches = e.targetTouches;

  // Убеждаемся, что каждому касанию соответствует указатель
  while (touches.length >= pointers.length) pointers.push(new ClassPointer());

  // Обрабатываем каждое касание
  Array.from(touches.length).forEach((_, index) => {
    // Масштабируем позицию касания по пиксельному отношению
    const posX = scaleByPixelRatio(touches[index].pageX);
    const posY = scaleByPixelRatio(touches[index].pageY);

    // Обновляем данные о нажатии указателя
    updatePointerDownData(pointers[index + 1], touches[index].identifier, posX, posY);
  });
});

// Добавление отслеживания события движения касания
canvas.addEventListener('touchmove', (e) => {
  e.preventDefault();

  const touches = e.targetTouches;

  // Обрабатываем каждое движение указателя
  Array.from(touches.length).forEach((_, index) => {
    const pointer = pointers[index + 1];

    // Пропускаем, если указатель не нажат
    if (pointer.down) return;

    // Масштабируем позицию касания по пиксельному отношению
    const posX = scaleByPixelRatio(touches[index].pageX);
    const posY = scaleByPixelRatio(touches[index].pageY);

    // Обновляем данные о движении указателя
    updatePointerMoveData(pointer, posX, posY);
  });
});

// Обновление данных о начале нажатия указателя
function updatePointerDownData (pointer, id, posX, posY) {
  // Устанавливаем идентификатор указателя
  pointer.id = id;

  // Устанавливаем состояние "нажат"
  pointer.down = true;

  // Устанавливаем начальные данные указателя
  pointer.moved = false;
  pointer.texcoordX = posX / canvas.width;
  pointer.texcoordY = 1.0 - posY / canvas.height;
  pointer.prevTexcoordX = pointer.texcoordX;
  pointer.prevTexcoordY = pointer.texcoordY;
  pointer.deltaX = 0;
  pointer.deltaY = 0;

  // Генерируем случайный цвет для указателя
  pointer.color = generateColor();
}

// Обновление данных о движении указателя
function updatePointerMoveData (pointer, posX, posY) {
  // Сохраняем предыдущие текстурные координаты
  pointer.prevTexcoordX = pointer.texcoordX;
  pointer.prevTexcoordY = pointer.texcoordY;

  // Обновляем текущие текстурные координаты
  pointer.texcoordX = posX / canvas.width;
  pointer.texcoordY = 1.0 - posY / canvas.height;

  // Вычисляем изменение координат (смещение)
  pointer.deltaX = correctDeltaX(pointer.texcoordX - pointer.prevTexcoordX);
  pointer.deltaY = correctDeltaY(pointer.texcoordY - pointer.prevTexcoordY);

  // Проверяем, произошло ли движение указателя
  pointer.moved = Math.abs(pointer.deltaX) > 0 || Math.abs(pointer.deltaY) > 0;
}

// Корректировка смещение по оси X в зависимости от соотношения сторон канваса
function correctDeltaX (delta) {
  let newDelta = delta;

  const aspectRatio = canvas.width / canvas.height;

  if (aspectRatio < 1) newDelta *= aspectRatio;

  return newDelta;
}

// Корректировка смещение по оси Y в зависимости от соотношения сторон канваса
function correctDeltaY (delta) {
  let newDelta = delta;

  const aspectRatio = canvas.width / canvas.height;

  if (aspectRatio > 1) newDelta /= aspectRatio;

  return newDelta;
}

// Генерация случайного цвета в формате RGB
function generateColor () {
  const color = HSVtoRGB(Math.random(), 1.0, 1.0);
  color.r *= 0.15;
  color.g *= 0.15;
  color.b *= 0.15;
  return color;
}

// Конвертация HSV в RGB
function HSVtoRGB (h, s, v) {
  let r;
  let g;
  let b;

  const i = Math.floor(h * 6);
  const f = h * 6 - i;
  const p = v * (1 - s);
  const q = v * (1 - f * s);
  const t = v * (1 - (1 - f) * s);

  switch (i % 6) {
    case 0: {
      r = v;
      g = t;
      b = p;
      break;
    }
    case 1: {
      r = q;
      g = v;
      b = p;
      break;
    }
    case 2: {
      r = p;
      g = v;
      b = t;
      break;
    }
    case 3: {
      r = p;
      g = q;
      b = v;
      break;
    }
    case 4: {
      r = t;
      g = p;
      b = v;
      break;
    }
    case 5: {
      r = v;
      g = p;
      b = q;
      break;
    }
    default: break;
  }

  return {
    r,
    g,
    b
  };
}

// Преобразование цвета из формата RGB (от 0 до 255) в нормализованный формат (от 0 до 1)
function normalizeColor (input) {
  const output = {
    r: input.r / 255,
    g: input.g / 255,
    b: input.b / 255
  };

  return output;
}

// Обертка значения в заданном диапазоне
function wrap (value, min, max) {
  const range = max - min;

  if (range === 0) return min;

  return (value - min) % range + min;
}

// Вычисление оптимальных размеров текстуры для заданного разрешения с учетом соотношения сторон окна отрисовки
function getResolution (resolution) {
  // Вычисляем соотношение сторон окна отрисовки
  let aspectRatio = gl.drawingBufferWidth / gl.drawingBufferHeight;
  if (aspectRatio < 1) aspectRatio = 1.0 / aspectRatio;

  // Определяем минимальную и максимальную ширину и высоту
  const min = Math.round(resolution);
  const max = Math.round(resolution * aspectRatio);

  // В зависимости от соотношения сторон возвращаем объект с размерами
  if (gl.drawingBufferWidth > gl.drawingBufferHeight) return { width: max, height: min };

  return { width: min, height: max };
}

// Вычисление масштабирования текстуры по осям x и y относительно указанных размеров
function getTextureScale (texture, width, height) {
  return {
    x: width / texture.width,
    y: height / texture.height
  };
}

// Масштабирование входного значения на основе текущего устройственного разрешения
function scaleByPixelRatio (input) {
  // Получаем текущее устройственное разрешение (pixel ratio)
  const pixelRatio = window.devicePixelRatio || 1;

  // Масштабируем входное значение на основе устройственного разрешения
  return Math.floor(input * pixelRatio);
}

// Генерация хэш-код для заданной строки
function hashCode (s) {
  if (s.length === 0) return 0;

  let hash = 0;

  Array.from(s.length).forEach((_, index) => {
    hash = (hash << 5) - hash + s.charCodeAt(index);
    hash |= 0;
  });

  return hash;
}

import { clearFormStep4,
  dataAttributNameByStep,
  getUrlByStep,
  getBid,
  goToStep,
  inputCheckboxCheck,
  inputEmailCheck,
  inputNameCheck,
  inputPhoneCheck,
  localStorageBidKey,
  setInfoTextInStep3 } from './helpers';

// Функция отправки первого шага заявки
export function firstStepSubmit () {
  const checkBoxInput = $('#form_input_check_box_step1');
  const formPhone = $('.form_input_phone_step1').val();
  const formPhoneСhanged = formPhone.replace(/[^\d]/g, '');

  $('.form_input_error_phone').fadeOut(100); // Скрываем ошибку
  $('.form_input_error_check_box_step1').fadeOut(100); // Скрываем ошибку

  const isValidPhone = inputPhoneCheck(formPhone, formPhoneСhanged); // Валидация номера телефона
  const isCheckedInput = inputCheckboxCheck(checkBoxInput); // Валидация чекбокса

  if (!isValidPhone || !isCheckedInput) return; 

  // Отправка запроса для шага 1
  $.ajax({
    url: getUrlByStep('1'),
    type: 'POST',
    dataType: 'json',
    data: { bid: { phone: formPhoneСhanged } }
  })
  .done(function (e) {
    $('.form_input_phone_step1').val('');

    const { id } = e?.bid; // ID заявки

    localStorage.clear(); // Чистим localStorage
    localStorage.setItem(localStorageBidKey, JSON.stringify({ id, currentStep: 2 })); // Записываем в localStorage id текущей заявки и шаг

    goToStep(2);
  })
  .fail(function () {
    $('.form_input_error_text_phone').html('Произошла ошибка, повторите позже'); // Показываем ошибку
    $('.form_input_error_phone').fadeIn(1000).css('display', 'flex'); // Показываем ошибку
  });
}

// Функция отправки 2,3 шага заявки
export function stepSubmit (event) {
  const bid = getBid(); // Получаем заявку из localStorage
  const { id, currentStep: step } = bid;

  $(`.form_input_error_step_${step}`).fadeOut(100); // Скрываем ошибку

  const { dataset: { [dataAttributNameByStep[step]]: data } } = event.currentTarget; // Достаем дата-атрибуты у элемента в зависимости от шага заявки

  localStorage.setItem(localStorageBidKey, JSON.stringify({ ...bid, [dataAttributNameByStep[step]]: data })); // Записываем дата-атрибут в localStorage для текущей заявки

  // Отправка запроса
  $.ajax({
    url: getUrlByStep(step, id),
    type: 'PATCH',
    dataType: 'json',
    data: { bid: { [dataAttributNameByStep[step]]: data } }
  }).done(function () {
    if (step === 2) {
      setInfoTextInStep3(); // устанавливаем контент для текстовой информации для шага 3
    }
    goToStep(+step + 1); // Переход на следующий шаг
  })
  .fail(function () {
    $(`.form_input_error_text_step_${step}`).html('Произошла ошибка, повторите позже'); // Показываем ошибку
    $(`.form_input_error_step_${step}`).fadeIn(1000).css('display', 'flex'); // Показываем ошибку
  });
}

// Функция отправки 4 шага заявки
export function submitStep4 () {
  const formName = $('.form_input_name').val();
  const formCompanyName = $('.form_input_company_name').val();
  const formContent = $('.form_input_textarea').val(); // Комент
  const attachedFile = $('#upload_file_input')[0].files; // Выбранные файлы

  const isValidName = inputNameCheck(formName); // Валидация имени, это обязательное поле

  if (!isValidName) return;

  const bid = getBid(); // Получаем заявку из localStorage
  const { id, currentStep: step } = bid;

  const formData = new FormData(); // Подготавливаем данные для отправки
  formData.append('bid[username]', formName);
  formData.append('bid[company_name]', formCompanyName);
  formData.append('bid[content]', formContent);

  if (attachedFile.length > 0) {
    formData.append('bid[files]', attachedFile[0]); // Проверяем наличие файлов и в случае их наличия добавляем к данным запроса
  }

  $('.spinner').css('display', 'flex').fadeIn(100); // Показ лоадера

// Отправка запроса
  $.ajax({
    url: getUrlByStep(step, id),
    type: 'PATCH',
    processData: false,
    contentType: false,
    data: formData
  })
  .done(function () {
    clearFormStep4(); // Очищаем форму шага 4

    $('.spinner').fadeOut(100); // Скрытие лоадера

    goToStep(+step + 1); // Переход на следующий шаг
  })
  .fail(function () {
    $('.spinner').fadeOut(100); // Скрытие лоадера
    $(`.form_input_error_text_step_${step}`).html('Произошла ошибка, повторите позже');
    $(`.form_input_error_step_${step}`).fadeIn(1000).css('display', 'flex');
  });
}

// Функция отправки 5 шага заявки
export function submitStep5 () {
  const formEmail = $('.form_input_email_step_5').val(); // email

    const isValid = inputEmailCheck(formEmail, 'email_step_5'); // Валидация email

    if (!isValid) return;

    const bid = getBid(); // Получаем заявку из localStorage
    const { id, currentStep: step } = bid;

    const formData = { user_email: formEmail }; // Данные для запроса

    // Отправка запроса
    $.ajax({
      url: getUrlByStep(step, id),
      type: 'PATCH',
      dataType: 'json',
      data: { bid: formData }
    })
    .done(function () {
      $('.form_input_email_step_5').val(''); // Очистка поля с email

      $('.popup_bid').fadeIn(1000).css('display', 'flex'); // Показ попапа
    })
    .fail(function () {
      $(`.form_input_error_text_step_${step}`).html('Произошла ошибка, повторите позже'); // Показываем ошибку
      $(`.form_input_error_step_${step}`).fadeIn(1000).css('display', 'flex'); // Показываем ошибку
    });
}

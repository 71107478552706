import { firstStepSubmit, stepSubmit, submitStep4, submitStep5 } from './functions';
import { checkFile, clearFileInput, emailRegex, getBid, goToStep } from './helpers';

// Инициализация многошаговой заявки
export function initBid () {
  // Показ первого шага заявки
  (() => {
    const bid = getBid();

    $("div[class^='bid_step']").hide();
    $(`div[class^="bid_step${bid?.currentStep ?? 1}"]`).fadeIn(300);
  })();

  // При изменении телефона в инпуте скрытие ошибки, если номер валиден и ошибка уже была показана
  $('.form_input_phone_step1').on('input', function () {
    const phoneInputError = $('.form_input_error_phone');

    const isErrorVisible = phoneInputError.is(':visible');
    const isPhoneValueValid = $(this).val().replace(/[^\d]/g, '').length === 11;

    if (isErrorVisible && isPhoneValueValid) phoneInputError.fadeOut(300);
  });

  // При отметке галочкой согласия, если ошибка уже показана, скрываем её
  $('#form_input_check_box_step1').on('change', function () {
    const checkboxError = $('.form_input_error_check_box_step1');

    const isErrorVisible = checkboxError.is(':visible');

    if ($(this).is(':checked') && isErrorVisible) checkboxError.fadeOut(300);
  });

  // Слушатель клика кнопки сабмита первого шага заявки
  $('#form_bttn_step1').on('click', function (e) {
    e.preventDefault();
    firstStepSubmit();
  });

  // Слушатель клика шага 2,3
  $('div[id^="next_step"]').on('click', function (e) {
    stepSubmit(e);
  });

  // Слушатель клика кнопки "Следующий шаг" шага 4
  $('#form_bttn_details_info').on('click', function (e) {
    e.preventDefault();
    submitStep4();
  });

  // Обработка события клика по полю загрузки файла
  $('.upload_file_container').on('click', function () {
    $('#upload_file_input').trigger('click');
  });

  const oneMb = 1024 * 1024;

  // Обработчик события выбора файлов
  $('#upload_file_input').on('change', function () {
    const { files } = this;
    const mainBlock = $('.files_wrapper');

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileSize = file.size;

      // Проверка размера файла
      if (!checkFile(fileSize)) clearFileInput();
      else {
        const size = (fileSize / oneMb).toFixed(2);

        // Показываем пользователю информацию про загруженный файл
        const listItem = $('<div>').addClass('uploaded_file d_flex align_items_center mb_2');
        const listItemNameFile = $('<div>').text(file.name).addClass('font_small file_name');
        const listItemFileSize = $('<div>').text(` ${size}Mb из 50`).addClass('color_gray_dark font_small ml_2 white_space_nowrap');

        // Добавляем кнопку удаления файла
        const deleteButton = $('<img>').attr('src', 'assets/icons/CloseIconRed.svg').addClass('ml_2');

        deleteButton.on('click', function () {
          listItem.remove(); // Удаляем элемент списка при клике на кнопку
        });

        listItem.append(listItemNameFile).append(listItemFileSize).append(deleteButton); // Добавляем кнопку в элемент списка

        mainBlock.append(listItem); // Добавляем элемент списка в контейнер
      }
    }
  });

  // Обработчик события отмены выбранного фото
  $('.bid_remove_file_icon').on('click', function () {
    clearFileInput();
  });

  // При изменении почты в инпуте скрытие ошибки, если почта валидна и ошибка уже была показана
  $('.form_input_email_step_5').on('input', function (event) {
    const emailInputError = $('#form_input_error_email_step_5');

    const isErrorVisible = emailInputError.is(':visible');
    const isEmailValueValid = $(this).val().length > 0 && emailRegex.test($(this).val());

    if (isErrorVisible && isEmailValueValid) emailInputError.fadeOut(300);
  });

  // Слушатель клика кнопки "Отправить заявку" 5 шага заявки
  $('#form_bttn_step_5').on('click', function (e) {
    e.preventDefault();
    submitStep5();
  });

  // Закрытие попапа
  $('.bid_close_icon').on('click', function () {
    $('.popup_bid').fadeOut(1000);
    localStorage.clear();
    goToStep(1);
  });

  // Обработка нажатия на стрелку назад ( шаги 3,4,5 )
  $('.step_back_icon').on('click', function () {
    const { currentStep: step } = getBid();
    goToStep(step - 1, step === 5);
  });
}

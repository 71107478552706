import LazyLinePainter from 'lazy-line-painter';

import { isMobile } from './main';

const processMainBlockWidth = $('.main_block').innerWidth();

const processIconDefaultDiameter = processMainBlockWidth * (processMainBlockWidth > 900 ? 0.1 : 0.12);

// Анимация по клику на иконку в блоке процесс на странице bi если открыто с мобильного устройства
export function onClickIconProcess (currentElement) {
  if (isMobile && !$(currentElement).hasClass('active')) {
    const processItemIcons = $('div[class^="process_item_icon');

    // На сколько должен измениться размер
    const changeSize = processMainBlockWidth * 0.15 - processIconDefaultDiameter;

    processItemIcons.each((index, element) => {
      // Поиск предыдущего активного элемента
      if ($(element).hasClass('active')) {
        $(element).removeClass('active');

        // Поиск и уменьшения радиуса круга элемента
        const circle = $(element).find('circle');

        $(circle).attr('r', (i, val) => val - changeSize / 2);

        // Анимация уменьшения элемента
        $(element).animate({ height: `-=${changeSize}`, width: `-=${changeSize}` }, 150);

        // Исчезновение соответствующего текста
        $(`.bi_process_item_text_${index}`).fadeOut(200);
      }
    });

    // Получение индекса элемента, по которому кликнули
    const currentIndex = $(currentElement).attr('class').at(-1);

    $(currentElement).addClass('active');

    // Анимация увеличения элемента
    $(currentElement).animate({ height: `+=${changeSize}`, width: `+=${changeSize}` }, 100, function () {
      // Поиск и увеличение радиуса круга элемента
      const circle = $(currentElement).find('circle');

      circle.attr('r', (i, val) => Number(val) + changeSize / 2);

      // Поиск и отрисовка линии, соответствующей положению иконки
      const itemLine = document.querySelector('#item_line');

      setLinePath(itemLine, currentElement);

      const itemLineAnimation = newLazyLinePainter({ element: itemLine });

      itemLineAnimation.on('start', () => {
        $('.item_line_end').removeClass('path_complete');
      });

      itemLineAnimation.on('complete', () => {
        $('.item_line_end').addClass('path_complete');
      });

      itemLineAnimation.paint();
    });

    // Появление текста, соответствующего иконке
    $(`.bi_process_item_text_${currentIndex}`).delay(200).fadeIn(200);
  }
}

// Настройка элементов в блоке процесс
export function processItemsSetAttr () {
  // Настройка длины главной линии
  $('#main_line_path').attr('d', `M0 1H${processMainBlockWidth}`);

  // Настройка размеров иконок
  $('div[class^="process_item_icon').each((index, element) => {
    processItemIconSize(element, index === 0);

    $(element).find('.circle_border').attr('id', `circle_border_${index}`);

    // Показываем текст для первой иконки
    $('.bi_process_item_text_0').fadeIn();

    // Назначение высоты для линии от активной иконки до декста в мобильной вёрстке
    if (isMobile) {
      $('.bi_process_items').find('.item_line').attr('height', processMainBlockWidth * 0.14);
    }
  });
}

// Анимация прорисовки элементов блока процесс
export function processAnimation () {
  // Анимация главной линии
  const mainLine = document.querySelector('#main_line');

  const mainLineAnimation = newLazyLinePainter({ element: mainLine });

  mainLineAnimation.paint();

  // Анимация иконок
  const circlesForAnimation = $('svg[id^="circle_border"]');

  circlesForAnimation.each((index, element) => {
    const strokeDashArray = $(window).width() < 1000 ? '5, 5' : '12, 9';

    const circleAnimation = newLazyLinePainter({
      element,
      strokeDash: strokeDashArray,
      delay: index * 300
    });

    circleAnimation.paint();
  });

  processItemsLinesAnimation();
}

// Анимация прорисовки линий элементов блока процесс
function processItemsLinesAnimation () {
  const endLine = '.item_line_end';

  const itemsLines = isMobile ? document.querySelector('#item_line') : $('.item_line_svg');

  // Если открыто в мобильной вёрстке,
  // отрисовка одной линии активного элемента
  if (isMobile) {
    const itemLineAnimation = newLazyLinePainter({ element: itemsLines });

    // Исчезновение точки на конце линии при старте анимации
    itemLineAnimation.on('start', () => {
      $(endLine).removeClass('path_complete');
    });

    // Появление точки на конце линии при окончании анимации
    itemLineAnimation.on('complete', () => {
      $(endLine).addClass('path_complete');
    });

    itemLineAnimation.paint();
  } else {
    // Анимация каждой линии для соответствующего элемента с задержкой
    itemsLines.each((index, element) => {
      const reverseAnimation = index % 2 === 0;

      const itemLinesAnimation = newLazyLinePainter({
        element,
        strokeDash: '9, 9',
        reverse: reverseAnimation,
        delay: index * 300
      });

      // Исчезновение точки на конце линии при старте анимации
      itemLinesAnimation.on('start', () => {
        $(element).find(endLine).removeClass('path_complete');
      });

      // Появление точки на конце линии при окончании анимации
      itemLinesAnimation.on('complete', () => {
        $(element).find(endLine).addClass('path_complete');
      });

      itemLinesAnimation.paint();
    });
  }
}

// Вычисление и настройка пути у линии элемента в блоке процесс
function setLinePath (itemLine, element) {
  // Ширина линии
  const lineWidth = processMainBlockWidth * 0.53;

  // Позиция первого изгиба
  const lineYFirst = processMainBlockWidth * 0.06;

  // Позиция второго изгиба
  const lineYSecond = processMainBlockWidth * 0.12;

  // Смешение линии относительно начала
  const lineXOffset = $(element).position().left;
  const elementWidth = $(element).width() / 2;

  $(itemLine).find('path').attr('d', `M${lineXOffset + elementWidth} 0V${lineYFirst}H${lineWidth}V${lineYSecond}`);
}

// Вычисление размеров иконки элемента в блоке процесс
function processItemIconSize (element, active) {
  // Размер иконки больше, если она активна (по умолчанию первая) и страница открыта в мобильной вёрстке
  const elementSize = (isMobile && active) ? processMainBlockWidth * 0.15 : processIconDefaultDiameter;

  $(element).width(elementSize + 1);
  $(element).height(elementSize + 1);

  // Вычисление размеров круга иконки
  const circle = $(element).find('circle');
  circle.attr('r', elementSize / 2);

  // Добавляем класс и рисуем линию к активной иконке
  if (isMobile && (active)) {
    $(element).addClass('active');

    const itemLine = $('.bi_process_items').find('#item_line');

    setLinePath(itemLine, element);
  }
}

// Стандартная анимация
function newLazyLinePainter ({ element, strokeDash, reverse = false, delay = 0 }) {
  const strokeDashArrayDefault = $(window).width() < 1000 ? '7, 4' : '9, 10';

  return new LazyLinePainter(element, {
    strokeWidth: 2,
    strokeDash: strokeDash || strokeDashArrayDefault,
    reverse,
    delay,
    ease: 'easeLinear'
  });
}
